/**
 * Products listing.
 * @module products/products
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React from 'react';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Route, Routes } from "react-router-dom";
import { ProductDetail } from './product-detail';
import { ProductsTracking } from './products-tracking';
import { Messenger } from '../comp/messenger.js';
import { WarehousesPackagings } from './warehouses-packagings';
import { ErrorWrap } from '../comp/errorwrap';
import { StocksSwitch } from './stocks';
import { date_formatISO } from '../lib/date-utils';
import { NotesList } from '../notes/notes-list';
import { useTranslation } from 'react-i18next';
import { ChooseCategory } from '../comp/choose-category';
import {ProductsCheckOverviewNew} from "./products-check-overview-new";
import {ProductsList} from "./ProductsList";

//seen all - OK

//         0               1                2              3          4                   5
//     KodProduktu    k_IDProduktu    NazevProduktu    k_ADR    KodPartneraDodavatel    Aktivni


export function Products({ user, userinfo, userlogin }) {
	const { t } = useTranslation();

	const defaultDate = date_formatISO(new Date());


	return (
		<ErrorWrap>
			<Nav variant="tabs" defaultActiveKey="/products/list" className="mb-3">
				<Nav.Item>
					<LinkContainer to="/products/list">
						<Nav.Link eventKey="/products/lists">{t('menu2-prod-list')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>
				{/*<Nav.Item>
					<LinkContainer to="/products/check">
						<Nav.Link eventKey="/products/check">{t('menu2-prod-check')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>*/}
				<Nav.Item>
					<LinkContainer to="/products/check/new">
						<Nav.Link eventKey="/products/check/new">{t('menu2-prod-check')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>
				<Nav.Item>
					<LinkContainer to="/products/tracking">
						<Nav.Link eventKey="/products/tracking">{t('menu2-prod-track')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>
				<Nav.Item>
					<LinkContainer to="/products/packagings">
						<Nav.Link eventKey="/products/packagings">{t('menu2-prod-pack')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>
				<Nav.Item>
					<LinkContainer to={"/products/stocks/" + defaultDate}>
						<Nav.Link eventKey={"/products/stocks/" + defaultDate}>{t('menu3-deno-stat-wh')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>
				<Nav.Item>
					<LinkContainer to="/products/notes">
						<Nav.Link eventKey="/products/notes">{t('menu2-notes')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>
				<Nav.Item className="ms-auto">
					<Routes>
						<Route path=':id/view' element={<Messenger userlogin={userlogin} type={'prod'} />} />
						<Route path='stocks/:fetchDate/inventory/:wh/*' element={<Messenger userlogin={userlogin} type={'inventory'} />} />
					</Routes>
				</Nav.Item>


			</Nav>

			<Routes>
				<Route path='list' element={
					<ProductsList />
				} />
				{/*<Route path='check' element={<ProductsCheckOverviewHorizontal userlogin={userlogin} />} />*/}
				<Route path='check/new' element={<ProductsCheckOverviewNew userlogin={userlogin} />} />
				<Route path=':id/view' element={<ProductDetail user={user} userinfo={userinfo} userlogin={userlogin} />} />
				<Route path='tracking' element={<ProductsTracking user={user} userinfo={userinfo} userlogin={userlogin} />} />
				<Route path='packagings' element={<WarehousesPackagings />} />
				<Route path='stocks/:fetchDate/*' element={<StocksSwitch userlogin={userlogin} />} />
				<Route path="notes" element={<NotesList userlogin={userlogin} diff_object_types={{ 1: false, 2: false, 3: true, 4: true }} />} />
				<Route path="" element={<ChooseCategory />} />
			</Routes>
		</ErrorWrap>
	);
}
