/**
 * List of orders purchase with filtering
 * @module orders/orders-purchase
 * @author Miroslav Lhoťan <miroslav.lhotan@netglade.cz>
 */

import React, { useState, useCallback, useEffect } from "react";
import { Table, Row, Col, Form, Dropdown, DropdownButton, Card } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { format_amount } from "../lib/format";
import { date_formatCZ } from "../lib/date-utils";
import { Boolean } from "../comp/boolean";
import { TableFetchStatusOverlay } from "../comp/FetchLoader";
import { ErrorWrap } from "../comp/errorwrap";
import { useTranslation } from "react-i18next";
import { currencyTitles } from "../lists/currency_titles";
import { DynamicLoadPaged, DynamicLoadPagedProps } from "../comp/DynamicLoadPaged";
import { SortIconsRhf } from "../comp/SortIconsRhf";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useDebounceFilterValues } from "../hooks/debounceFilterValues";
import BooleanDropdownRhf from "../comp/BooleanDropdownRhf";
import { useQuery } from "@tanstack/react-query";
import { queryStatusToLoadedStatus } from "../api/common";
import { getPurchaseOrders } from "../api/orders";
import { OrderBy } from "../lib/utilTypes";

interface OrdersPurchasePagedFilterValues {
  orderCode?: string;
  partnerCode?: string;
  note?: string;
  massFilter?: string;
  amount?: string;
  currencyCode?: string;
  closed?: boolean | null;
  receivedPercent?: string;
  invoicedPercent?: string;
  orderBy?: OrderBy<Dto.GetPurchaseOrdersItemRangePagedResponse>;
}

const initialFilter: OrdersPurchasePagedFilterValues = {
  orderCode: "",
  partnerCode: "",
  note: "",
  massFilter: "",
  amount: "",
  currencyCode: "",
  closed: null,
  receivedPercent: "",
  invoicedPercent: "",
  orderBy: null,
};

export function OrdersPurchasePaged() {
  const [offset, setOffset] = useState(0);
  const [from, setFrom] = useState<string>(undefined);
  const [to, setTo] = useState<string>(undefined);

  const form = useForm<OrdersPurchasePagedFilterValues>({
    mode: "onChange",
    defaultValues: initialFilter,
  });
  const { watch } = form;
  const [debouncedFilter] = useDebounceFilterValues(watch, initialFilter);

  useEffect(() => {
    form.register("orderBy");

    return () => form.unregister("orderBy");
  }, [form]);

  const { data, status, isFetching, refetch } = useQuery({
    queryKey: ["purchaseOrders", from, to, offset, debouncedFilter],
    queryFn: ({ signal }) => {
      if (!from || !to)
        return Promise.resolve<Dto.GetPurchaseOrdersItemRangePagedResponse>({
          items: [],
          totalCount: 0,
          filteredCount: 0,
          rangeCount: 0,
          count: 0,
        });
      return getPurchaseOrders(from, to, 20, offset, debouncedFilter, debouncedFilter.orderBy, { signal });
    },
  });
  const loadedStatus = queryStatusToLoadedStatus(status, isFetching);

  const loadData = useCallback<DynamicLoadPagedProps["loadData"]>(function (startDate, endDate) {
    setFrom(startDate);
    setTo(endDate);
  }, []);

  return (
    <ErrorWrap>
      <DynamicLoadPaged
        period="1y"
        loadData={loadData}
        offset={offset}
        setOffset={setOffset}
        cleanFilters={() => form.reset(initialFilter)}
        reloadIt={refetch}
        filteredCount={data?.filteredCount}
        loadingState={loadedStatus}
        totalCount={data?.totalCount}
        rangeCount={data?.rangeCount}
      />
      <TableFetchStatusOverlay status={loadedStatus}>
        <FormProvider {...form}>
          <OPWeb showOrders={data?.items ?? []} />
          <OPApp showOrders={data?.items ?? []} />
        </FormProvider>
      </TableFetchStatusOverlay>
    </ErrorWrap>
  );
}

interface OPListProps {
  showOrders: Dto.GetPurchaseOrdersItemRangePagedResponse["items"];
}

function OPWeb({ showOrders }: OPListProps) {
  const { t } = useTranslation();
  const { register, unregister, watch, setValue } = useFormContext<OrdersPurchasePagedFilterValues>();

  useEffect(() => {
    register("currencyCode");

    return () => {
      unregister("currencyCode");
    };
  }, [register, unregister]);

  const currency = watch("currencyCode");

  //console.log(direction);
  //console.log(sortValue);
  return (
    <div className="d-none d-lg-block">
      <Table hover size="sm" striped>
        <thead className="beGray">
          <tr>
            <th className="pb-2">
              <Form.Label className="pb-1">
                <p className="mb-2">{t("doc_code")}</p>
                <SortIconsRhf columnName="orderCode" filterFieldName="orderBy" />
              </Form.Label>
              <Form.Group controlId="filterCode">
                <Form.Control type="text" placeholder={"🔍 "} {...register("orderCode")} />
              </Form.Group>
            </th>
            <th className="pb-2">
              <Form.Group controlId="filterPartner">
                <Form.Label>{t("biz-partner_code")}</Form.Label>
                <Form.Control type="text" placeholder={"🔍 "} {...register("partnerCode")} />
              </Form.Group>
            </th>
            <th style={{ width: "8%" }} className="pb-3">
              <p className="mb-2 pb-1">{t("date")}</p>
              <SortIconsRhf columnName="orderDate" filterFieldName="orderBy" numeric />
            </th>
            <th className="pb-2">
              <Form.Group controlId="filterNote">
                <Form.Label>{t("note")}</Form.Label>
                <Form.Control type="text" placeholder={"🔍 "} {...register("note")} />
              </Form.Group>
            </th>
            <th className="pb-2 text-end">
              <Form.Group controlId="filterAmount">
                <Form.Label>{t("biz-amount_money")}</Form.Label>
                <Form.Control type="text" placeholder="&#128269; > < =" {...register("amount")} />
              </Form.Group>
            </th>
            <th className="pb-2">
              <p className="mb-2">{t("biz-currency")}</p>
              <DropdownButton id="filterCurrency" title={t(currencyTitles[currency])} variant="light">
                {Object.keys(currencyTitles).map((title, idx) => (
                  <Dropdown.Item
                    key={idx}
                    onClick={() => {
                      setValue("currencyCode", title);
                    }}
                  >
                    {t(currencyTitles[title])}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </th>
            <th className="pb-2">
              <p className="mb-2">{t("biz-closed")}</p>
              <BooleanDropdownRhf name="closed" />
            </th>
            <th className="pb-2 text-end">
              <Form.Group controlId="filterAccepted">
                <Form.Label>{t("sys-accepted")}</Form.Label>
                <Form.Control type="text" placeholder="&#128269; > < =" {...register("receivedPercent")} />
              </Form.Group>
            </th>
            <th className="pb-2 text-end">
              <Form.Group controlId="filterInvoiced">
                <Form.Label>{t("biz-invoiced")}&nbsp;%</Form.Label>
                <Form.Control type="text" placeholder="&#128269; > < =" {...register("invoicedPercent")} />
              </Form.Group>
            </th>
          </tr>
        </thead>
        <tbody>
          {showOrders.map(function (ord) {
            return (
              <LinkContainer
                to={{
                  pathname: "/orders/purchase/" + ord.orderCode,
                }}
                key={ord.orderCode}
              >
                <tr key={ord.orderCode}>
                  <td>{ord.orderCode}</td>
                  <td>{ord.partnerCode}</td>
                  <td>{date_formatCZ(ord.orderDate)}</td>
                  <td>{ord.note}</td>
                  <td className="text-end">{format_amount(ord.totalAmount)}</td>
                  <td>{ord.currencyCode}</td>
                  <td className="text-center">
                    <Boolean value={ord.closed} variant="onlyTrue" />
                  </td>
                  <td className="text-end">{ord.receivedPercent}</td>
                  <td className="text-end">{ord.invoicedPercent}&nbsp;%</td>
                </tr>
              </LinkContainer>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

/*
 * Returns list of orders purchase for mobile with merged filter
 *
 * @param {any} show_orders - list of orders to show on one page
 * @returns {component}
 */
function OPApp({ showOrders }: OPListProps) {
  const { t } = useTranslation();
  const { register, watch, setValue } = useFormContext<OrdersPurchasePagedFilterValues>();
  const currency = watch("currencyCode");

  return (
    <div className="d-lg-none">
      <br />
      <Card className="m-0 p-2 mb-2">
        <Card.Body className="m-0 p-0">
          <Row className="mb-2">
            <Col>
              <p className="mb-0 bolder">{t("doc_code")}</p>
              <SortIconsRhf columnName="orderCode" filterFieldName="orderBy" />
            </Col>
            <Col>
              <p className="mb-0 bolder">{t("date")}</p>
              <SortIconsRhf columnName="orderDate" filterFieldName="orderBy" />
            </Col>
          </Row>

          <p className="mb-1">{t("mass_filter")}</p>
          <Form.Group controlId="filterCodex" className="mb-2">
            <Form.Control
              type="text"
              placeholder={"🔍 " + t("filter_doc_code_note_partner")}
              {...register("massFilter")}
            />
          </Form.Group>

          <Row>
            <Col>
              <Form.Group controlId="filterAmount">
                <Form.Label className="mb-1">{t("biz-amount_money")}</Form.Label>
                <Form.Control type="text" placeholder="&#128269; > < =" {...register("amount")} />
              </Form.Group>
            </Col>
            <Col xs="auto">
              <p className="mb-1">{t("biz-currency")}</p>
              <DropdownButton id="filterCurrency" title={t(currencyTitles[currency])} variant="light">
                {Object.keys(currencyTitles).map((title, idx) => (
                  <Dropdown.Item
                    key={idx}
                    onClick={() => {
                      setValue("currencyCode", title);
                    }}
                  >
                    {t(currencyTitles[title])}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <p className="mb-1">{t("biz-closed")}</p>
              <BooleanDropdownRhf name="closed" />
            </Col>
            <Col>
              <Form.Group controlId="filterAccepted">
                <Form.Label className="mb-1">{t("sys-accepted")}</Form.Label>
                <Form.Control type="text" placeholder="&#128269; > < =" {...register("receivedPercent")} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="filterInvoiced">
                <Form.Label className="mb-1">{t("biz-invoiced")}&nbsp;%</Form.Label>
                <Form.Control type="text" placeholder="&#128269; > < =" {...register("invoicedPercent")} />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Table striped>
        <tbody>
          {showOrders.map(function (ord) {
            return (
              <LinkContainer
                to={{
                  pathname: "/orders/purchase/" + ord.orderCode,
                }}
                key={ord.orderCode}
              >
                <tr>
                  <td>
                    <Row className="g-0">
                      <Col className="beBigger me-1">{ord.orderCode}</Col>
                      <Col className="text-end align-bottom">
                        {format_amount(ord.totalAmount)} {ord.currencyCode}
                      </Col>
                    </Row>
                    <Row>
                      <Col>{ord.partnerCode}</Col>
                      <Col className="text-end">
                        {t("biz-closed")}: <Boolean value={ord.closed} variant="onlyTrue" />
                      </Col>
                    </Row>
                    <Row>
                      <Col>{date_formatCZ(ord.orderDate)}</Col>
                      <Col className="text-end">
                        {t("sys-accepted")}: {ord.receivedPercent}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {t("note_short")}: {ord.note}
                      </Col>
                      <Col xs="auto" className="text-end">
                        {t("biz-invoiced")}: {ord.invoicedPercent}&nbsp;%
                      </Col>
                    </Row>
                  </td>
                </tr>
              </LinkContainer>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
