import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { BooleanDropdown } from "./boolean";

interface BooleanDropdownRhfProps {
  name: string;
  className?: string;
}

export default function BooleanDropdownRhf({ name, className }: BooleanDropdownRhfProps) {
  const form = useFormContext();

  return (
    <Controller
      control={form.control}
      render={({ field: { onChange, value } }) => {
        return <BooleanDropdown onChange={onChange} variant="" value={value} className={className} />;
      }}
      name={name}
    />
  );
}
