import React, { useState } from "react";
import { his_fetch_loading, his_fetch_success, HisFetchStatus, TableFetchStatusOverlay } from "../comp/FetchLoader";
import { ErrorWrap } from "../comp/errorwrap";
import { Col, Form, Row, Table } from "react-bootstrap";
import { ItemsAndFilteredPaged } from "../comp/dynamic-load";
import { Pager } from "../comp/pager";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import BooleanDropdownRhf from "../comp/BooleanDropdownRhf";
import { useDebounceFilterValues } from "../hooks/debounceFilterValues";
import { useQuery } from "@tanstack/react-query";
import { getProducts } from "../api/products";
import { queryStatusToLoadedStatus } from "../api/common";

interface ProductsListFilterValues {
  productCode?: string;
  productId?: string;
  productName?: string;
  supplierPartnerCode?: string;
  adr?: boolean;
  active?: boolean;
  massFilter?: string;
}

const defaultFilter: ProductsListFilterValues = {
  productCode: "",
  productId: "",
  productName: "",
  supplierPartnerCode: "",
  adr: null,
  active: null,
  massFilter: "",
};

export function ProductsList() {
  const pageSize = 20;

  const [offset, setOffset] = useState(0);
  const form = useForm<ProductsListFilterValues>({
    mode: "onChange",
    defaultValues: defaultFilter,
  });
  const { watch, reset } = form;
  const [debouncedFilter] = useDebounceFilterValues(watch, defaultFilter, 500);

  const {
    data: products,
    status,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["products", offset, debouncedFilter],
    queryFn: ({ signal }) => getProducts(pageSize, offset, debouncedFilter, { signal }),
  });
  const loadedStatus = queryStatusToLoadedStatus(status, isFetching);

  const reloadIt = () => {
    return refetch();
  };

  if (!products && !his_fetch_success(loadedStatus)) {
    return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />;
  }

  return (
    <ErrorWrap>
      <Row>
        <Col xs="12" sm={0} md="4"></Col>
        <Col xs="12" sm="6" md="4" className="text-center mb-1">
          <ItemsAndFilteredPaged
            filteredCount={products.filteredCount}
            totalCount={products.totalCount}
            cleanFilters={() => reset()}
          />
        </Col>
        <Col xs="12" sm="6" md="4">
          <Pager
            offset={offset}
            pagesize={pageSize}
            total={products.filteredCount}
            callback={setOffset}
            disabled={his_fetch_loading(loadedStatus)}
          />
        </Col>
      </Row>
      <TableFetchStatusOverlay status={loadedStatus}>
        <FormProvider {...form}>
          <ProductsWeb products={products.items} />
          <ProductsApp products={products.items} />
        </FormProvider>
      </TableFetchStatusOverlay>
      <Pager
        offset={offset}
        pagesize={pageSize}
        total={products.filteredCount}
        callback={setOffset}
        disabled={his_fetch_loading(loadedStatus)}
      />
    </ErrorWrap>
  );
}

function ProductsApp({ products }) {
  const { t } = useTranslation();
  const { register } = useFormContext<ProductsListFilterValues>();

  return (
    <div className="d-lg-none">
      <Form.Group controlId="filterMass">
        <Form.Label className="mb-1">{t("mass_filter")}</Form.Label>
        <Form.Control
          type="text"
          className="mb-3"
          placeholder={"🔍 " + t("filter_id_code_name_supplier")}
          {...register("massFilter")}
        />
      </Form.Group>
      <Row>
        <Col className="text-center">
          {t("active")}
          <BooleanDropdownRhf name="active" className="beGrayButton" />
        </Col>
        <Col className="text-center">
          {t("prod-ADR")}
          <BooleanDropdownRhf name="adr" className="beGrayButton" />
        </Col>
      </Row>
      <Table size="sm" striped className="mt-3">
        <tbody>
          {products.map((row) => (
            <ProductsRowApp row={row} key={row.productCode} />
          ))}
        </tbody>
      </Table>
    </div>
  );
}

function ProductsRowApp({ row }) {
  const { t } = useTranslation();

  return (
    <LinkContainer
      to={{
        pathname: "/products/" + encodeURIComponent(row.productCode) + "/view",
      }}
      key={row.productCode}
    >
      <tr>
        <td>
          <Row>
            <Col xs="auto">{row.productId}</Col>
            <Col className="text-end">
              {t("active")}:{" "}
              {row.active ? (
                <span role="img" aria-label="ano">
                  Ano &#9989;
                </span>
              ) : (
                "Ne"
              )}{" "}
            </Col>
          </Row>
          <Row>
            <Col xs="auto">{row.productCode}</Col>
            <Col className="text-end">
              {t("prod-ADR")}:{" "}
              {row.adr ? (
                <span role="img" aria-label="ano">
                  Ano &#9989;
                </span>
              ) : (
                "Ne"
              )}
            </Col>
          </Row>
          <Row>
            <Col xs="auto">{row.productName}</Col>
            <Col className="text-end">
              {t("prod-bbd_batch_settings")}.: {row.warehouseConfiguration?.type}
            </Col>{" "}
            {/**tady bude potřeba sjednocení */}
          </Row>
          <Row>
            <Col xs="auto">
              {t("prod-supplier")}: {row.supplierPartnerCode}
            </Col>
            <Col className="text-end">
              {row.warehouseConfiguration?.months ? (
                <>
                  {t("months")}: {row.warehouseConfiguration?.months}
                </>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </td>
      </tr>
    </LinkContainer>
  );
}

function ProductsWeb({ products }) {
  const { t } = useTranslation();
  return (
    <Table className="d-none d-lg-table" hover size="sm" striped>
      <thead className="beGray">
        <ProductListWebFilter />
      </thead>
      <tbody>
        {products.map(function (pr) {
          return (
            <LinkContainer
              key={pr.productCode}
              to={{
                pathname: "/products/" + encodeURIComponent(pr.productCode) + "/view",
              }}
            >
              <tr>
                <td width="10%" className="align-middle">
                  {pr.productId}
                </td>
                <td width="20%" className="align-middle">
                  {pr.productCode}
                </td>
                <td width="30%" className="align-middle">
                  {pr.productName}
                </td>
                <td width="5%" className="text-center align-middle">
                  {pr.adr ? (
                    <span role="img" aria-label="ano">
                      {t("yes")} &#9989;
                    </span>
                  ) : (
                    t("no")
                  )}
                </td>
                <td width="15%" className="align-middle">
                  {pr.supplierPartnerCode}
                </td>
                <td width="5%" className="text-center align-middle">
                  {pr.active ? (
                    <span role="img" aria-label="ano">
                      {t("yes")} &#9989;
                    </span>
                  ) : (
                    t("no")
                  )}
                </td>
                <td width="5%" className="text-center">
                  {pr.warehouseConfiguration?.type}
                </td>
                <td width="5%" className="text-end pe-3">
                  {pr.warehouseConfiguration?.months}
                </td>
              </tr>
            </LinkContainer>
          );
        })}
      </tbody>
    </Table>
  );
}

function ProductListWebFilter() {
  const { register } = useFormContext<ProductsListFilterValues>();
  const { t } = useTranslation();

  return (
    <>
      <tr>
        <th rowSpan={2} className="pb-3">
          <Form.Group controlId="filterID">
            <Form.Control type="text" placeholder={"🔍 " + t("not-prod_id")} {...register("productId")} />
          </Form.Group>
        </th>
        <th rowSpan={2} className="pb-3">
          <Form.Group controlId="filterCode">
            <Form.Control type="text" placeholder={"🔍 " + t("prod-code")} {...register("productCode")} />
          </Form.Group>
        </th>
        <th rowSpan={2} className="pb-3">
          <Form.Group controlId="filterName">
            <Form.Control type="text" placeholder={"🔍 " + t("prod-name")} {...register("productName")} />
          </Form.Group>
        </th>
        <th rowSpan={2} className="text-center pb-3" style={{ paddingBottom: "21px" }}>
          {t("prod-ADR")}
          <BooleanDropdownRhf name="adr" />
        </th>
        <th rowSpan={2} className="pb-3">
          <Form.Group controlId="filterPartner">
            <Form.Control
              type="text"
              placeholder={"🔍 " + t("prod-supplier_partner")}
              {...register("supplierPartnerCode")}
            />
          </Form.Group>
        </th>
        <th rowSpan={2} className="text-center pb-3" style={{ paddingBottom: "21px" }}>
          {t("active")}
          <BooleanDropdownRhf name="active" />
        </th>
        <th colSpan={2} className="text-center">
          {t("prod-batch_expiry")}
        </th>
      </tr>
      <tr>
        <th className="text-center pb-2">{t("prod-settings")}</th>
        <th className="text-center pb-2">{t("months")}</th>
      </tr>
    </>
  );
}
