/**
 * Complex alert cards with props as TO DO, etc.
 * @module comp/problems-card
 * @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
 */
import React from 'react';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import { Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useTranslation } from 'react-i18next';

//seen all - OK

/*
 * Special type of alert used when synchronicity of database was broken.
 * 
 * @param {string} sentence - variable part of error message
 * @returns {componet}
 */
export function SyncProblemHugeAlert({ sentence }) {
    const { t } = useTranslation();
    return (
        <Alert variant='danger' className="p-2 text-center my-3">
            <h2 className='m-5'>
                ⚠️
                <b>{t('contact_head_now')}</b>, {t('data_intengrity_fail')}, {sentence}.
                ⚠️
            </h2>
        </Alert>
    );
}

/**
 * Card with header, explanition and possibly toto-list
 * 
 * @param {string} headerOk - text in case of success
 * @param {string} textOk - explanation in case of success
 * @param {object} todo  - todo list with error
 * @param {string} variant - color of card
 * @returns {component}
 */
export function OkOrProblemsCard({ headerOk, textOk, todo, variant }) {
    const { t } = useTranslation();

    if (variant === "success") {
        return (
            <Alert variant="success">
                <h5>{headerOk}</h5>
                <p>{textOk}</p>
            </Alert>
        );
    } else {
        return (
            <Alert variant={variant}>
                <Card.Title>{variant === 'danger' ? t('ord-problems') : t('warnings')}</Card.Title>
                {todo}
            </Alert >
        );
    }
}

/** 
 * Returns number of newest history log - current status. 
 * @param {dictionary} historyLog - available history logs
 * @return {number} - number of state of the newest entry in historyLog
 */
export function newest_history_log(historyLog) {
    if (historyLog.length === 0) {
        return 0;
    } else {
        const reversed_history = [...historyLog].reverse();
        //console.log(reversed_history[0].status);
        return reversed_history[0].status;
    }
    // zreversuje a vrátí number z  první pozice :: const reversed_history = [...history].reverse();
    // if lenght je 0, tak return 0 a potom až reverse 
}


/** 
 * Returns content and style of card originally called OkOrProblemsCard. 
 * @param {dictionary} historyLog - available history logs
 * @param {function} todo - todo list in some weird structure
 * @param {boolean} stornoTime - whether we should allow storno of delivery-note
 * @return {dictionary} - specification og label, varint and possible extra content for card OkOrProblemsCard.  
 */
export function get_card_content(historyLog, readyToExport, stornoTime) { // exportIt, cancelIt
    //řešit přes vyextrahovaný status, protože pak mi tam přibude ještě jeden
    const current_status = newest_history_log(historyLog);
    // console.log(current_status);
    const card_visual_spec = {
        1: { title: "cant_send", variant: "danger", showTodo: true },
        2: { title: "ready_to_send", variant: "success", showSend: true },
        3: { title: "prod-sent", variant: "info" },
        4: { title: "storno_processing", variant: "secondary" },
        5: { title: "storno_completed", variant: "secondary" },
        6: { title: "delivered", variant: "primary" },
        7: { title: "canceled", variant: "secondary" },  //canceled - pokud zruším dodák - výdej zrušen - šedivý
        8: { title: "deno_denied", variant: "danger" },       // 4 - zamítnuto - další karta - červený (výdej zamítnut)
    };
    //která čísla logů jdou do kterých stylů a barev
    const history_states_card_style_pairing = {
        24: 3, 23: 3, 25: 3, 1: 3, 2: 3, 3: 3, 26: 3,  5: 3, 12: 3, 27: 3, 15: 3, 16: 3, 17: 3, 28: 3, 18: 3, 19: 3, 14: 3,
        29: 4, 9: 4, 21: 4, 22: 4, 30: 4, 10: 4,
        13: 5, 11: 5,
        20: 6, 31: 6,
        8: 7, // 8: dodací list ručně zrušen
        4: 8, // 4: Dodací list zamítnut

    };

    if (current_status === 0) { //tady by šel možná dopsat ten stav 26 - chyba v CSV
        if (!readyToExport) {
            return card_visual_spec[1];
        } else {
            return card_visual_spec[2];
        }
    } else {
        const alert_type = card_visual_spec[history_states_card_style_pairing[current_status]];
        //console.log(alert_type);
        if (stornoTime) {
            return { ...alert_type, showCancel: true };
        }
        return alert_type;
    }
}


/** 
 * Displays the state, stornoAction todo and Export button of PD. 
 *  
 * @param {dictionary} historyLog - history of PD entries
 * @param {function} todo - list of errors/warnings to fix
 * @param {function} exportIt - function for sending PD
 * @param {function} cancelIt - function for canceling PD
 * @param {string} mess - text of error/success message
 * @param {boolean} updatingStatus - whether the is some updating proccess - exporting or storno of PD
 * @param {boolean} stornoTime - whether we can calcel the PD
 * @return {type} - Card with background color and info/instructions about PD state
 */
export function OkOrProblemsCardPD({ historyLog, todo, readyToExport, exportIt, cancelIt, mess, updatingStatus, stornoTime }) {
    const { t } = useTranslation();

    //lokální binding - když si chci naimportovat slovník
    // binduju si všechny potenccionální klíče, co mi mohou přijít
    // studio mi poví, co nevyužívám
    const { title, variant, showSend, showTodo, showCancel } = get_card_content(historyLog, readyToExport, stornoTime);
    // const lala = get_card_content(...); - to výše je lepší

    // not ready to export - errors
    // ready to export
    // exported and procceding 
    // exported and storno can be send
    // storno sent
    // storno accepted
    // 
    return (
        <Alert style={{ minHeight: "120px" }} className="clearfix" variant={variant}>
            <h5 className='mb-4 mt-1'>{t(title)}</h5>
            {showTodo ? todo : ""}
            {showSend ? <div className=' text-end'><Button disabled={updatingStatus} onClick={exportIt}>{t('send')}</Button></div> : ""}
            {showCancel ? <div className=' text-end'><Button disabled={updatingStatus} onClick={cancelIt}>{t('deno-storno')}</Button></div> : ""}
            {mess ? <div className={mess[1] + " mt-1 text-end"}>{mess[0]}</div> : ""}
        </Alert>
    )
}

/**
 * Specific problem-card for order purchase. It has special behavior and props. See below. It could have been done way better.
 * 
 * @param {boolean} headerOk - header of OK card
 * @param {string} textOk - text of OK card
 * @param {function} todo - list of problems at NO
 * @param {string} variant - color of the card
 * @param {string} NO - code of order purchase
 * @param {boolean} showButton - whether allow stock notification - button "Avizovat"
 * @param {boolean} noWarehouse - whether NO belongs to supported warehouses
 * @param {boolean} allNotifiedNO - whether there is still some amount to notify
 * @param {boolean} deliveryNO - if NO is for delivery, no stockin notification is enabled
 * @returns {component}
 */
export function OkOrProblemsCardNO({ headerOk, textOk, todo, variant, NO, showButton, noWarehouse, allNotifiedNO, deliveryNO = false }) {
    const { t } = useTranslation();
    if (deliveryNO) {
        return (
            <Alert variant="primary">
                <h5>{t('ord-delivery')}</h5>
                <p>{t('ord-delivery_no_stockin')}.</p>
            </Alert>
        );
    }
    if (noWarehouse) {
        return (
            <Alert variant="secondary">
                <h5>{t('ord-unsupported_wh')}</h5>
                <p>{t('ord-cannot_notify_unsupported')}. <b>{t('ord-notify_as_before')}.</b></p>
            </Alert>
        );
    }
    if (allNotifiedNO) {
        return (
            <Alert variant="info">
                <h5>{t('ord-all_notified')}</h5>
                <p>{t('ord-nothing_to_notify')}.</p>
            </Alert>
        );
    }

    // TODO: disable/enable for stock notifications
    const doShowButton = showButton;
    if (variant === "success") {
        return (
            <>
                <Alert variant="success">
                    <h5>{headerOk}</h5>
                    <p>{textOk}</p>
                    {doShowButton ?
                        <div className="text-end">
                            <LinkContainer to={{ pathname: "/orders/purchase/" + NO + "/notification/1" }}>
                                <Button variant="success"><h4 className="mb-0">{t('ord-notify')}</h4></Button>
                            </LinkContainer>
                        </div>
                        : ""}
                    <p>{noWarehouse}</p>
                </Alert>
            </>
        );
    } else {
        return (
            <>
                <Alert variant={variant} className="mb-0">
                    <Card.Title>{variant === 'danger' ? t('ord-problems') : t('warnings')}</Card.Title>
                    {todo}
                </Alert >
                {doShowButton ?
                    <>
                        <Alert variant="success" className="mt-0 clearfix">
                            <span> {t('ord-you_can_notify_ok_items')}.</span>
                            <LinkContainer to={{ pathname: "/orders/purchase/" + NO + "/notification/1" }} className="float-end">
                                <Button variant="success"><h4 className="mb-0">{t('ord-notify')}</h4></Button>
                            </LinkContainer>
                        </Alert>
                    </>
                    : ""
                }
            </>
        );
    }
}
