import React from "react";

export interface UserLoginData {
  userName: string;
  logged: boolean;
  userInfo: Dto.GetUserInfoResponse;
}

const UserLoginContext = React.createContext<UserLoginData | undefined>(undefined);

interface UserLoginProviderProps {
  userLoginData: UserLoginData;
  children: React.ReactNode;
}

export function useUserLogin() {
  const context = React.useContext(UserLoginContext);
  if (!context) {
    throw new Error("useUserLogin must be used within a UserLoginProvider");
  }
  return context;
}

export const UserLoginProvider: React.FC = ({ children, userLoginData }: UserLoginProviderProps) => {
  return <UserLoginContext.Provider value={userLoginData}>{children}</UserLoginContext.Provider>;
};
