import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { DefaultError, useMutation, useQueries } from "@tanstack/react-query";
import {
  cancelSendDeliveryNote,
  getDeliveryNoteDetail,
  getDeliveryNoteStatusLogs,
  sendDeliveryNote,
} from "../../api/deliveryNotes";
import { make_checker } from "../../comp/checker";
import {
  check_amount_fits_packages,
  check_expiration,
  dnote_validate_all_texts,
  dnote_validate_DatDokladuPRP9,
  dnote_validate_DatExpirace,
  dnote_validate_DatumDodani,
  dnote_validate_DatumDodani_soft,
  dnote_validate_DIN_Phone,
  dnote_validate_DodaciAdresa,
  dnote_validate_DodaciAdresaData,
  dnote_validate_DodaciPodminka,
  dnote_validate_DopravniProstredek,
  dnote_validate_FakturacniAdresaData,
  dnote_validate_HSH_DIN,
  dnote_validate_items,
  dnote_validate_KodAdresyDodani,
  dnote_validate_KodDopravce,
  dnote_validate_KodKategorie,
  dnote_validate_KodStrediska,
  dnote_validate_KodZakazky,
  dnote_validate_partner,
  dnote_validate_SingleWarehouse,
  dnote_validate_TypAdresyFakturacni,
  dnote_validate_Uzavreno,
} from "./deliveryNoteChecks";
import {
  check_card_in_warehouse,
  check_package_weight_wh_values,
  check_product_batches_expirations_monitoring,
  check_product_expiration,
  check_product_his_approval,
  check_product_hsh_adr,
  check_product_hsh_amount,
  check_product_hsh_container,
  check_product_hsh_density,
  check_product_hsh_MJ,
  check_product_twist_approval,
  check_product_warehouse_adr,
  check_product_warehouse_container,
  check_product_warehouse_density,
  check_product_wh_amount,
  check_product_wh_MJ,
  check_suppliers_addresses,
  package_weight_compare,
} from "../../products/product-checks";
import { check_package_weight_kg_input, get_package_weight_kg } from "../../lib/generic-checks";
import Fraction from "fraction.js";
import { ResultMessage } from "../../comp/resultmessage";
import { date_formatCZ } from "../../lib/date-utils";
import { storno_allowed_states } from "../progress-delivery-note-def";
import { newest_history_log, OkOrProblemsCardPD, SyncProblemHugeAlert } from "../../comp/problems-card";
import { ErrorWrap } from "../../comp/errorwrap";
import {
  AdditionalInfo,
  DeliveryInfo,
  GeneralInfo,
  Header,
  PDItemsWeb,
  ProcessedDeliveryNoteItem,
} from "./DeliveryNoteDetailWeb";
import { Card, Col, Row } from "react-bootstrap";
import { ProgressPathDeliveryNote } from "../ProgressPathDeliveryNote";
import { Emails } from "../../emails";
import { useTranslation } from "react-i18next";
import { useUserLogin } from "../../UserLoginProvider";
import { his_fetch_success, HisFetchStatus } from "../../comp/FetchLoader";
import { queryStatusToLoadedStatus } from "../../api/common";
import { PDItemsMobile } from "./DeliveryNoteDetailApp";

function DeliveryNoteDetail() {
  const { t } = useTranslation();
  const { orderCode } = useParams();
  const [reloadingPeriod, setReloadingPeriod] = useState(0);
  const userLogin = useUserLogin();
  const [mess, setMess] = useState<undefined | [string, string]>(undefined); // error message při odesílání do skladu

  const [
    { refetch: refetchNote, data: deliveryNote, status: deliveryNoteStatus, isFetching: deliveryNoteFetching },
    { refetch: refetchLog, data: stockoutLog, status: stockoutLogStatus },
  ] = useQueries({
    queries: [
      {
        queryKey: ["deliveryNote", orderCode],
        queryFn: ({ signal }) => {
          return getDeliveryNoteDetail(orderCode, { signal });
        },
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ["deliveryNoteStatusLogs", orderCode],
        queryFn: ({ signal }) => {
          return getDeliveryNoteStatusLogs(orderCode, { signal });
        },
        refetchInterval: reloadingPeriod,
      },
    ],
  });

  const { mutate: send, isPending: sending } = useMutation<
    Dto.PutSendDeliveryNoteResponse,
    DefaultError,
    "send" | "cancel"
  >({
    mutationFn: (operation) => {
      return operation === "send" ? sendDeliveryNote(orderCode) : cancelSendDeliveryNote(orderCode);
    },
    onSuccess: (_, operation) => {
      if (operation === "send") setReloadingPeriod(10 * 1000);
      setMess([t("sent_successfully"), "text-success"]);
    },
    onError: () => {
      setMess([t("sending_fail"), "text-danger"]);
    },
  });

  function reloadIt() {
    refetchNote();
    refetchLog();
  }

  const deliveryNoteLoadedStatus = queryStatusToLoadedStatus(deliveryNoteStatus, deliveryNoteFetching);
  const stockoutLogLoadedStatus = queryStatusToLoadedStatus(deliveryNoteStatus, false);

  if (!his_fetch_success(deliveryNoteLoadedStatus) || !his_fetch_success(stockoutLogLoadedStatus)) {
    return (
      <HisFetchStatus
        status={deliveryNoteLoadedStatus}
        loadingType="big"
        errorType="fetcherError"
        reloadButton={reloadIt}
      />
    );
  }

  const PD_sent = stockoutLog.length > 0;
  const { performCheck, ErrorBadge, TodoList, checksOK } = make_checker();

  // je dodák uzavřen?
  // má dodák položky?
  // je to podporovaný sklad?
  // poté zbytek kontrol
  if (!PD_sent) {
    performCheck("closed", "danger", function () {
      return dnote_validate_Uzavreno(deliveryNote);
    });
    const successfully_closed = checksOK("closed");
    if (successfully_closed) {
      performCheck("noItems", "danger", function () {
        return dnote_validate_items(deliveryNote);
      });
      const itemsOK = checksOK("noItems");
      if (itemsOK) {
        performCheck("noWarehousePartner", "danger", function () {
          return dnote_validate_partner(deliveryNote);
        });
        const supportedWh = checksOK("noWarehousePartner");
        if (supportedWh) {
          if (deliveryNote && deliveryNote.items && deliveryNote.items.length > 0) {
            performCheck("warehouseProblems", "danger", function () {
              return dnote_validate_KodDopravce(deliveryNote);
            });
            performCheck("AddressProblems", "danger", function () {
              return dnote_validate_DodaciAdresa(deliveryNote);
            });
            performCheck("singleWarehouse", "danger", function () {
              return dnote_validate_SingleWarehouse(deliveryNote);
            });
          }
          performCheck("deliveryCondition", "danger", function () {
            return dnote_validate_DodaciPodminka(deliveryNote);
          });
          performCheck("deliveryDateSoft", "warning", function () {
            return dnote_validate_DatumDodani_soft(deliveryNote);
          });
          performCheck("deliveryDate", "danger", function () {
            return dnote_validate_DatumDodani(deliveryNote);
          });
          performCheck("DodaciAdresaAllData", "danger", function () {
            return dnote_validate_DodaciAdresaData(deliveryNote);
          });
          performCheck("hsh-din", "danger", function () {
            return dnote_validate_HSH_DIN(deliveryNote);
          });
          performCheck("din-phone", "danger", function () {
            return dnote_validate_DIN_Phone(deliveryNote);
          });
          performCheck("missing-vehicle", "danger", function () {
            return dnote_validate_DopravniProstredek(deliveryNote);
          });
          performCheck("InvoicingAddressType", "danger", function () {
            return dnote_validate_TypAdresyFakturacni(deliveryNote);
          });
          performCheck("InvoicingAddressData", "danger", function () {
            return dnote_validate_FakturacniAdresaData(deliveryNote);
          });
          performCheck("wrongAddress", "danger", function () {
            return dnote_validate_KodAdresyDodani(deliveryNote);
          });
          if (checksOK("closed")) {
            performCheck("CentreCode", "danger", function () {
              return dnote_validate_KodStrediska(deliveryNote);
            });
            performCheck("contractCode", "danger", function () {
              return dnote_validate_KodZakazky(deliveryNote);
            });
            performCheck("categoryCode", "danger", function () {
              return dnote_validate_KodKategorie(deliveryNote);
            });
          }
          performCheck("expiryMissing", "danger", function () {
            return dnote_validate_DatExpirace(deliveryNote);
          });
          // console.log("Controlling PRP9");
          performCheck("PRP9Delivery", "danger", function () {
            return dnote_validate_DatDokladuPRP9(deliveryNote);
          });
          performCheck("missingText", "danger", function () {
            return dnote_validate_all_texts(deliveryNote);
          });
          check_suppliers_addresses(performCheck, "", deliveryNote.customerData, false, true);
        }
      }
    }
  }

  //ppj - proddejní produkt jednotka a bere se z položky PD
  // spj - skladovací produkt jednotka - produktová kodMjSkl daného produktu a k ní mj hmotnosti

  //console.log(document.customer_data);

  const preprocessedItems = deliveryNote.items.map(function (item): ProcessedDeliveryNoteItem {
    // 666: "Počítání hmotnosti obalu v kg" - zobrazení i pro check
    //console.log(item);
    const [packageWeightInputsOK_HSH] = check_package_weight_kg_input(
      item.series.unitOfMeasurementWeight,
      item.series.unitOfMeasurementUnit,
      item.packageUnitOfMeasurementQuantity
    ); //spj_MjHmotnostiMj je správně
    const package_weight_kg_hsh = packageWeightInputsOK_HSH
      ? get_package_weight_kg(
          item.series.unitOfMeasurementWeight,
          item.series.unitOfMeasurementUnit,
          item.packageUnitOfMeasurementQuantity
        )
      : null;
    const [packageWeightInputsOK_wh] = check_package_weight_kg_input(
      item.warehouseCard.unitOfMeasurementWeightInKg,
      "kg",
      item.warehouseCard.packageUnitOfMeasurementQuantity
    ); //ze skladu to nechodí
    const package_weight_kg_wh = packageWeightInputsOK_wh
      ? get_package_weight_kg(
          item.warehouseCard.unitOfMeasurementWeightInKg,
          "kg",
          item.warehouseCard.packageUnitOfMeasurementQuantity
        )
      : null;
    // const worth_display1 = item.pjo_MnozstviSklMjVObalu && item.spj_HmotnostMj;
    // const package_weight_kg_hsh_count = Fraction(item.spj_HmotnostMj).mul(Fraction(item.pjo_MnozstviSklMjVObalu));
    /////////// ------------------////
    // když nahradí vnitřek tohoto checku check_package_weight_wh_values tímto: check_package_weight_kg_input, tak se nikdy nespustí check package_weight_compare a tímto mi nevadí 2x &mdash;
    ///////////---------------------///
    // const package_weight_kg_hsh = worth_display1 ? String(package_weight_kg_hsh_count) : <>&mdash;</>;
    //const worth_display2 = item.pcr_MnozstviSklMjVObalu && item.pcr_HmotnostMjVKg;
    // const package_weight_kg_wh_count = Fraction(item.pcr_HmotnostMjVKg).mul(Fraction(item.pcr_MnozstviSklMjVObalu));
    //const package_weight_kg_wh = worth_display2 ? String(package_weight_kg_wh_count) : <>&mdash;</>;
    //---------------------
    const package_quantity_count = new Fraction(item.stockOut.stockQuantity).div(item.packageUnitOfMeasurementQuantity);
    const package_quantity = String(package_quantity_count);
    const altNames = [item.externalProductCode, item.externalProductName].filter((s) => (s || "").length > 0);
    const altNamesStr = altNames.length > 0 ? altNames.join(", ") : "";

    if (!PD_sent) {
      if (checksOK("noWarehousePartner")) {
        check_expiration(performCheck, item.productId, item.expirationDate, true);
        check_product_twist_approval(performCheck, item.productId, item.approvedInTwist, true);
        check_product_his_approval(performCheck, item.productId, item.needsApproval, item.approvedInHis, true);
        const configured = item.warehouseConfiguration.type > 0;
        check_product_batches_expirations_monitoring(performCheck, +item.productId, configured, true);
        check_product_expiration(
          performCheck,
          +item.productId,
          item.warehouseConfiguration.type,
          item.warehouseConfiguration.months,
          true
        );

        check_product_hsh_density(
          performCheck,
          "hsh-" + item.productId,
          item.series.unitOfMeasurementWeight,
          item.series.unitOfMeasurementUnit
        );
        check_product_hsh_amount(performCheck, "hsh-" + item.productId, item.packageUnitOfMeasurementQuantity);
        check_product_hsh_MJ(performCheck, "hsh-" + item.productId, item.storageUnitOfMeasurementCode, true);
        check_product_hsh_container(performCheck, "hsh-" + item.productId, item.hshPackage);
        check_product_hsh_adr(
          performCheck,
          "hsh-" + item.productId,
          item.adr,
          item.classAdr,
          item.adrPackingGroup?.toString(),
          item.adrUnNumber
        );

        const soFarSoGood = checksOK("hsh-" + item.productId + "$");

        if (soFarSoGood) {
          check_card_in_warehouse(
            performCheck,
            item.stockOut.bookCode + "-" + item.productId,
            item.warehouseCard.repeats
          );
          if (checksOK("missing-card-" + item.stockOut.bookCode + "-" + item.productId)) {
            check_product_warehouse_density(
              performCheck,
              "wh-" + item.stockOut.bookCode + "-" + item.productId,
              item.warehouseCard.unitOfMeasurementWeightInKg,
              item.series.unitOfMeasurementWeight,
              true
            );
            check_product_wh_amount(
              performCheck,
              "wh-" + item.stockOut.bookCode + "-" + item.productId,
              item.warehouseCard.packageUnitOfMeasurementQuantity,
              item.packageUnitOfMeasurementQuantity,
              true
            );
            check_product_wh_MJ(
              performCheck,
              "wh-" + item.stockOut.bookCode + "-" + item.productId,
              item.warehouseCard.storageUnitOfMeasurementCode,
              item.storageUnitOfMeasurementCode,
              true
            );
            check_package_weight_wh_values(
              performCheck,
              "wh-" + item.stockOut.bookCode + "-" + item.productId,
              item.warehouseCard.packageUnitOfMeasurementQuantity,
              item.warehouseCard.unitOfMeasurementWeightInKg
            ); // může to být špatně, ale nesmí to chybět
            if (checksOK("missing-amount-or-density-wh-" + item.stockOut.bookCode + "-" + item.productId)) {
              package_weight_compare(
                performCheck,
                "wh-" + item.stockOut.bookCode + "-" + item.productId,
                package_weight_kg_hsh,
                package_weight_kg_wh
              );
            }
            check_product_warehouse_container(
              performCheck,
              "wh-" + item.stockOut.bookCode + "-" + item.productId,
              item.hshPackage,
              Number.parseInt(item.stockOut.bookCode),
              item.warehouseCard.hshPackage,
              true
            );
            check_product_warehouse_adr(
              performCheck,
              "wh-" + item.stockOut.bookCode + "-" + item.productId,
              item.adr,
              item.classAdr,
              item.adrPackingGroup?.toString(),
              item.adrUnNumber,
              item.warehouseCard.adrClass,
              item.warehouseCard.adrPackingGroup,
              item.warehouseCard.adrUnNumber,
              true
            );
          }
        }
        check_amount_fits_packages(
          performCheck,
          item.series.itemId?.toString(),
          package_quantity_count,
          item.stockOut.stockQuantity,
          t
        );
      }
    }
    const no_card = !checksOK("missing-card-" + item.stockOut.bookCode + "-" + item.productId); //když to neproběhne, hlásí false, to je dobré
    //console.log(no_card);
    const redish = no_card ? " bg-red" : "";

    return {
      ...item,
      packageWeightKgHsh: package_weight_kg_hsh,
      packageWeightKgWh: package_weight_kg_wh,
      packageQuantity: package_quantity_count,
      alternativeNameStrings: altNamesStr,
      noCard: no_card,
      redish: redish,
    };
  });

  const readyToExport = checksOK() && stockoutLog.length === 0;

  if (deliveryNote?.header === null) {
    let message = t("deno_loading_err") + orderCode + t("please_call_support");
    if (deliveryNote?.items && deliveryNote.items.length === 0) {
      message += t("no_items_deno");
    }
    return <ResultMessage message={message} variant="danger" reloadButton={reloadIt} />;
  }

  if (deliveryNote.deliveryNoteCode?.match(/^PD20[2-9][0-9]977/)) {
    return <ResultMessage message={t("testing_deno")} variant="danger" />;
  }

  const header = deliveryNote.header;
  const KodDopravce = header.carrierCode;
  const dnote = deliveryNote;
  const realDatumDodani =
    KodDopravce === "SAMOODBĚR" || KodDopravce === "HSHLOGISTIKA"
      ? dnote.textPrp9
        ? dnote.textPrp9.text
        : "-"
      : date_formatCZ(header.deliveryDate);
  const cancel_conditions_ok =
    deliveryNote.warehousePartner === "LF" && deliveryNote.header.cancelled && deliveryNote.items.length === 0;

  const canBeCanceled = storno_allowed_states.includes(newest_history_log(stockoutLog)) && cancel_conditions_ok;
  const isADR = deliveryNote.items.filter((item) => item.classAdr).length > 0;

  return (
    <ErrorWrap>
      <Header reloadIt={reloadIt} kodDokladu={deliveryNote.deliveryNoteCode} document={deliveryNote} />

      <Row className="mt-4">
        <Col xs="12" md="3">
          <OkOrProblemsCardPD
            historyLog={stockoutLog}
            todo={<TodoList />}
            readyToExport={readyToExport}
            exportIt={() => send("send")}
            cancelIt={() => send("cancel")}
            mess={mess}
            updatingStatus={sending}
            stornoTime={canBeCanceled}
          />
        </Col>
        <Col className="pt-2">
          <ProgressPathDeliveryNote oneHistory={stockoutLog} setReloadingPeriod={setReloadingPeriod} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs="12" md="6" xl="4">
          <GeneralInfo
            ErrorBadge={ErrorBadge}
            datumDokladu={deliveryNote.header.deliveryDate}
            vystavil={deliveryNote.header.issuedByCode}
            kodPartnera={deliveryNote.header.partnerCode}
            nazevPartnera={deliveryNote.header.partnerName}
            typAdresyFaktur={deliveryNote.header.partnerAddress.addressType}
            nazevAdresy={deliveryNote.header.partnerAddress.addressName}
            adresa={deliveryNote.header.partnerAddress.address}
            adresa2={deliveryNote.header.partnerAddress.address2}
            misto={deliveryNote.header.partnerAddress.city}
            kodStatu={deliveryNote.header.partnerAddress.countryCode}
            psc={deliveryNote.header.partnerAddress.zipCode}
            stredisko={deliveryNote.header.stockOut.departmentCode}
            zakazka={deliveryNote.header.stockOut.orderCode}
            kategorie={deliveryNote.header.stockOut.categoryCode}
            uzavreno={deliveryNote.header.closed}
            schvaleno={deliveryNote.header.approved}
            poznamka={deliveryNote.header.stockOut.note}
            kodAdresy={deliveryNote.header.invoiceAddressCode}
          />
        </Col>
        <Col xs="12" md="6" xl="4" className="pb-3">
          <DeliveryInfo
            ErrorBadge={ErrorBadge}
            realDatumDodani={realDatumDodani}
            KodDopravce={KodDopravce}
            kodDoprProstredku={header.transportationCode}
            kodDodaciPodminky={header.deliveryConditionCode}
            dopravce={deliveryNote.header.carrierCode}
            kodAdresyDodani={deliveryNote.header.deliveryAddressCode}
            nazevAdresy={deliveryNote.header.deliveryAddress.addressName}
            adresa={deliveryNote.header.deliveryAddress.address}
            adresa2={deliveryNote.header.deliveryAddress.address2}
            misto={deliveryNote.header.deliveryAddress.city}
            kodStatu={deliveryNote.header.deliveryAddress.countryCode}
            psc={deliveryNote.header.deliveryAddress.zipCode}
            poznamka={deliveryNote.header.deliveryAddress.note}
            telefon={deliveryNote.header.deliveryAddress.phoneNumber}
            mobil={deliveryNote.header.deliveryAddress.mobilePhoneNumber}
            zastupcePartnera={deliveryNote.header.partnerRepresentative}
          />
        </Col>
        <Col xs="12" md="12" xl="4" className="pb-4">
          <AdditionalInfo ErrorBadge={ErrorBadge} isADR={isADR} document={deliveryNote} />
        </Col>
      </Row>
      <Items items={preprocessedItems} header={header} ErrorBadge={ErrorBadge} />
      {deliveryNote.emails.length > 0 ? (
        <>
          <Card className="mt-4 mb-4">
            <Card.Body className="px-0 pb-0">
              <Emails
                userlogin={userLogin}
                pagesize={5}
                existingEmails={deliveryNote.emails}
                noFetch
                optionalHeader={t("deno-mails") + " " + deliveryNote.deliveryNoteCode}
              />
            </Card.Body>
          </Card>
        </>
      ) : (
        ""
      )}
    </ErrorWrap>
  );
}

export default DeliveryNoteDetail;

function Items({ items, header, ErrorBadge }) {
  return (
    <ErrorWrap>
      <Card className="mb-4 mt-2">
        <Card.Body className="p-0 m-0">
          <PDItemsWeb items={items} header={header} ErrorBadge={ErrorBadge} />
          <PDItemsMobile items={items} header={header} ErrorBadge={ErrorBadge} />
        </Card.Body>
      </Card>
    </ErrorWrap>
  );
}
