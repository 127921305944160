/**
 * Multiple selecting filtering dropdown.
 * @module comp/MultipleSelectRhf
 * @author Miroslav Lhoťan <miroslav.lhotan@netglade.cz>
 */

import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

export interface MultipleSelectRhfProps {
  id: string;
  values: { [key: string]: string };
  withoutNumber?: boolean;
  width?: string;
}

export function MultipleSelectRhf({ id, values, withoutNumber = false, width = "inherit" }: MultipleSelectRhfProps) {
  const { t } = useTranslation();
  const form = useFormContext();

  const value = form.watch(id) as string[];

  function make_title() {
    const indexesOfChosen = value;
    const numberOfItems = indexesOfChosen.length;
    if (numberOfItems === Object.keys(values).length) {
      return t("all");
    } else if (numberOfItems === 0) {
      return t("not_choosen");
    } else if (numberOfItems === 1) {
      return t(values[indexesOfChosen[0]]);
    } else if (numberOfItems < 6) {
      return indexesOfChosen.join(", ");
    } else {
      return indexesOfChosen.slice(0, 5).join(", ") + "...";
    }
  }

  return (
    <DropdownButton id={id} title={make_title()} variant="light" className="mb-1">
      <div className="mx-3 mt-1" style={{ width: width }}>
        <Form.Check
          className="fw-normal mb-1"
          id={id + "0"}
          type="checkbox"
          label={t("all")}
          checked={value?.length === Object.keys(values).length}
          onChange={(e) => {
            if (e.target.checked) {
              form.setValue(id, Object.keys(values));
            } else {
              form.setValue(id, []);
            }
          }}
        />
        {Object.keys(values).map(function (key) {
          const label = withoutNumber ? t(values[key]) : key + " – " + t(values[key]);
          return (
            <Form.Check
              key={key}
              className="fw-normal mb-1"
              id={id + key + 1}
              type="checkbox"
              label={label}
              checked={value?.includes(key)}
              onChange={() => {
                form.setValue(id, value?.includes(key) ? (value?.filter((v) => v !== key) ?? []) : [...value, key]);
              }}
            />
          );
        })}
      </div>
    </DropdownButton>
  );
}
