/**
 * Component displaying boolean values in diffrent ways, booleanDropdown for filtering booleans.
 * @module comp/boolean
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */

import React from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';

//seen all - OK

/** 
 * Displaying boolean value Yes-No, etc. 
 * 
 * @typedef {object} BooleanProps
 * @property {boolean} value - boolean value
 * @property {string} variant - name of prefered variant, we have 7 so far
 * 
 * @param {BooleanProps} props - properties
 * @return - string for boolean value (eg. true => Yes)
 */
export function Boolean({ value, variant }) {
	const { t } = useTranslation();

	switch (variant) {
		case "true_false":
			if (value) {
				return <span role="img" aria-label="yes">{t('yes')} ✔️</span>;
			} return <span role="img" aria-label="yes">{t('no')} ❌</span>;
		case "onlyTrue":
			if (value) {
				return <span role="img" aria-label="yes">{t('yes')} &#9989;</span>;
			} return t('no');
		case "negative":
			if (value) {
				return <span className="text-danger">{t('canceled')}</span>;
			} return t('no');
		case "pomlcka":
			if (value) {
				return (value);
			} return "–";
		case "product-checks":
			if (value) {
				return <span>{t('problematic')} ❌</span>;
			} return "OK ✅";
		case "order-tracking":
			if (value) {
				return <span>{t('problematic')} 🔥</span>;
			} return "OK ✅";
		case "piw-problem":
			if (value) {
				return <span>❌</span>;
			} return "";
		default:
			if (value) {
				return <span role="img" aria-label="yes">{t('yes')}</span>;
			}
			return <span role="img" aria-label="no">{t('no')}</span>;
	}
}


/** 
 * Boolean dropdown for filtering boolean values. 
 * 
 * @typedef {object} BooleanDropdownProps
 * @property {string} variant - name of prefered variant
 * @property {boolean} value - boolean value
 * @property {function} onChange - onChange function for filter
 * @property {string} className - additional classes
 * 
 * @param {BooleanDropdownProps} props - properties
 * @return - Rendered dropdown
 */
export function BooleanDropdown({ onChange, variant, value, className = "" }) {
	const { t } = useTranslation();

	var booleanValues = {
		null: "",
		true: "",
		false: ""
	};
	switch (variant) {
		case "negative":
			booleanValues[null] = "all";
			booleanValues[true] = "canceled";
			booleanValues[false] = "no";
			break;
		case "product-checks":
			booleanValues[null] = "all";
			booleanValues[true] = "problematic"; //❌
			booleanValues[false] = "ok"; //✅ 
			break;
		case "order-tracking":
			booleanValues[null] = "all";
			booleanValues[true] = "problematic"; //❌
			booleanValues[false] = "ok"; //✅ 
			break;
		default:
			booleanValues[null] = "all";
			booleanValues[true] = "yes";
			booleanValues[false] = "no";
	}
	return (
		<DropdownButton title={t(booleanValues[value])} variant="light" className={"sto " + className}>
			<Dropdown.Item onClick={() => onChange(null)}>
				{t('all')} &nbsp;
			</Dropdown.Item>
			<Dropdown.Item onClick={() => onChange(true)} >
				<Boolean value={true} variant={variant} />
			</Dropdown.Item>
			<Dropdown.Item onClick={() => onChange(false)} >
				<Boolean value={false} variant={variant} />
			</Dropdown.Item>
		</DropdownButton>
	);
}