/**
 * Dynamic loading management for faster loading of long lists.
 * @module comp/dynamic-load
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */

import React, { useState, useEffect, useRef } from 'react';
import { date_getTomorrow, date_formatISO, date_parseISO, date_formatCZ, date_isLastInMonth } from '../lib/date-utils';
import { Button, Image, Row, Col } from 'react-bootstrap';
import { Loading } from './loading';
import { mesice } from '../lists/months';
import { Pager } from './pager';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { DropdownButton } from 'react-bootstrap';
import { RiFilterOffLine } from 'react-icons/ri';
import { quarters } from '../lists/quarters';
import { useTranslation } from 'react-i18next';

//seen all -  OK

/** 
 * Function returning props to period - first day and count of months.  
 * @param {string} period_spec - w - week, m - month, q - quarter, y - year (q,y => convert to months)
 * @param {date} today - date to start from
 * 
 * @return - dictionary with keys number of months in period, period - can be month or week, current - first date of the period in ISO
 */
export function parse_period(period_spec, today) {
	const found = period_spec.match(/([0-9]*)([wmqy])/i)
	const number = found[1];
	const period_letter = found[2].toLowerCase();
	const current = date_parseISO(today);
	switch (period_letter) {
		case 'w':
			while (current.getDay() !== 1) {
				current.setDate(current.getDate() - 1);
			}
			break;
		case 'm':
			current.setDate(1);
			break;
		case 'q':
			current.setDate(1);
			while ((current.getMonth() % 3) > 0) { //dokud je zbytek nenulový tak iterativně ubírámm jedničku
				current.setMonth(current.getMonth() - 1);
			}
			break;
		case 'y':
			current.setMonth(0);
			current.setDate(1);
			break;
		default:
			break;
	};
	const multipliers = {
		'w': 1,
		'm': 1,
		'q': 3,
		'y': 12
	}
	const mapped_letters = {
		'w': 'w',
		'm': 'm',
		'q': 'm',
		'y': 'm'
	}
	return {
		number: number * multipliers[period_letter],
		period: mapped_letters[period_letter],
		current: date_formatISO(current)
	};
}

/** 
 * Function returning first day of next period.  
 * @param {string} date - date of first day of  period in ISO format
 * @param {object} period - period duration in weeks or months
 * 
 * @return - ISO date of first date of previous period
 */
export function subtract_period(date, period) {
	var result = date_parseISO(date);
	//const sign = add ? + : - ;
	for (var i = 0; i < period.number; i++) {
		if (period.period === 'w') {
			result.setDate(result.getDate() - 7);
		} else if (period.period === 'm') {
			result.setMonth(result.getMonth() - 1);
		} else {
			throw new Error("Wrong period");
		}
	}
	return date_formatISO(result);
}

/** 
 * Component with buttons for dynamic loading and label of displayed period, realod button, number of items/filtered and pager.  
 * @param {string} period - period to be loaded (a slice of time) N[wmqy], default N=1
 * @param {boolean} load_previous - whether we want to add previous period to loading
 * @param {function} loadData - function for api request
 * @param {dictionary} filtered_data - entries at the page after filtering
 * @param {dictionary} data - full list of entries
 * @param {number} offset - start of pager
 * @param {function} setOffset - function changing start of pager
 * @param {string} totalCount - total number of entries in database
 * @param {string} oldest - date of oldest entry in database
 * @param {function} cleanFilters - function for cleaning all filters on the page
 * @param {boolean} flexibleRange - if true, first end_date is null
 * @param {number} loadedCount - null, if data has flat structure; number, if data from backend comes in flat structure and frontend makes tree, then we need loadedCount/totalCount instead of data/totalCount
 * @return - Visual header with reload button, dynamic loading button, items/filtered and pager.
 */
export function DynamicLoad({
	period, load_previous = false, loadData,
	filtered_data, data, offset, setOffset, totalCount, oldest, cleanFilters, flexibleRange = false, loadedCount = null
	// lastNormal = false
}) { //if flexibleRange, tak první end_date bude null
	const { t } = useTranslation();

	const its_last_in_month = date_isLastInMonth();

	const first_end_date = date_formatISO(date_getTomorrow());
	const load_period = parse_period(period, first_end_date);
	const first_start_date = (load_previous || its_last_in_month) ? subtract_period(load_period.current, load_period) : load_period.current;
	//override_load_previous
	const [realCurrentStart, setRealCurrentStart] = useState(first_start_date);
	const [wannabeStart, setWannabeStart] = useState(first_start_date);
	const [wannabeEnd, setWannabeEnd] = useState(first_end_date);
	const [loadingState, setLoadingState] = useState(0); //states: 0 - loading, 1 - ok, 2 - error 
	const loadDataStornoRef = useRef(null);

	const reloadIt = () => {
		setLoadingState(0);
		//než zavolám nové loadData, tak se třeba ujistit, že neběží starý 
		if (loadDataStornoRef.current !== null) {
			loadDataStornoRef.current();
		} //musím to zavolat jako funkci, pokud je prázdná, nic se nestane, ale pokud to běží, tak se to stornuje
		loadDataStornoRef.current = loadData(realCurrentStart, first_end_date, result_received, true);
	}

	function result_received(result) {
		loadDataStornoRef.current = null;
		if (result) {
			setLoadingState(1);
		} else {
			setLoadingState(2);
		}
	}

	function go_back() {
		setLoadingState(0);
		const new_end_date = realCurrentStart;
		const new_start_date = subtract_period(new_end_date, load_period);
		setWannabeStart(new_start_date);
		setWannabeEnd(new_end_date);
		if (loadingState === 1) {
			setRealCurrentStart(new_start_date);
		}
	}

	useEffect(() => {
		const finalEnd = flexibleRange ? null : wannabeEnd;
		loadDataStornoRef.current = loadData(wannabeStart, finalEnd, result_received); //funkce se předává bez () //wannabeend by mělo být null 
		return function () {
			if (loadDataStornoRef.current !== null) {
				loadDataStornoRef.current(); //hodnotu volám jako funkci
			}
		}
	}, [flexibleRange, wannabeEnd, wannabeStart, loadData]);

	const loading = loadingState === 0;
	const dataEnd = new Date(realCurrentStart) < new Date(oldest);
	const variant = loadingState === 2 ? "danger" : "primary";
	const loadedStuff = (loadedCount ? loadedCount : data.length) + "/" + (totalCount ? totalCount : "-");


	return (
		<>
			{/* <div>Request: {wannabeStart} - {wannabeEnd}</div> */}
			<Row className="g-0">
				<Col xs="12" md="4">
					<Button size="sm" className="me-2 d-inline" onClick={reloadIt}><Image src="/img/reload.svg" height="19" /></Button>

					<ButtonGroup style={{ width: "200px" }}>
						<Button size="sm"
							variant={variant}
							disabled={loading || dataEnd}
							onClick={go_back}
							title={dataEnd ? t('all_loaded') : ""}
						>
							{loading ? <Loading message={t('loading')} margin="0" size="small" /> :
								<>
									{t('show_also')}{loadingState === 2 ? nameOfCurrentPeriod(period, wannabeStart, t) : nameOfPreviousPeriod(period, realCurrentStart, t)}
								</>
							}
						</Button>

						<DropdownButton size="sm" as={ButtonGroup} title="" id="bg-nested-dropdown">
							<div className='px-3 py-2' style={{ maxWidth: "300px", width: "300px" }}>
								<small className='text-muted'>{t('loaded')} {loadedStuff} {t('records_from_db')}.
									<br />
									{t('oldest_record')}: {oldest ? date_formatCZ(oldest) : "-"}.</small>
							</div>
						</DropdownButton>
					</ButtonGroup>
					<span className='' style={{ minWidth: "300px", maxWidth: "300px" }}>
						&nbsp;&nbsp;
						{t('showing')}&nbsp;{displayPeriod(realCurrentStart, first_start_date, period, loadingState, t)} &nbsp;
					</span>
					{loadingState === 2 ? <><br /><small className='ms-5  mt-1'>⚠️ {t('loading_fail')}</small></> : ""}
				</Col>
				<Col xs="12" md="4" className='text-center'>
					<ItemsAndFiltered filtered_data={filtered_data} data={data} cleanFilters={cleanFilters} />
				</Col>
				<Col xs="12" md="4">
					<Pager offset={offset} pagesize={20} total={filtered_data.length} callback={setOffset} />
				</Col>
			</Row>
		</>
	);
}
/**
 * Returns name of displayed period or loading
 * 
 * @param {string} realCurrentStart - ISO date of start_date of currently concted period
 * @param {string} first_start_date - ISO date of very first start date of the period at first load of page
 * @param {string} period - period
 * @param {number} loadingState - if 1 - loading, displays already loaded periods or dash
 * @param {function} t - translate function
 * @return {string} - human label of the currently loaded period

 */
export function displayPeriod(realCurrentStart, first_start_date, period, loadingState, t) {
	const firstLoad = realCurrentStart === first_start_date;
	//console.log(add_period(startDate, period));
	if (firstLoad) {
		return loadingState === 1 ? nameOfCurrentPeriod(period, realCurrentStart, t) : "---";
	} else {
		return (<>{nameOfCurrentPeriod(period, realCurrentStart, t)}&nbsp;-&nbsp;{nameOfCurrentPeriod(period, first_start_date, t)}</>);
	}
}

/**
 * Returns name and year of the previous period to load
 * 
 * @param {string} period - timespan week, month, quarter, year
 * @param {string} startDate - ISO date of the previous period
 * @param {function} t - translate function
 * @return {string} - human label of the previous period to load
 */

export function nameOfPreviousPeriod(period, startDate, t) {
	const load_period = parse_period(period, startDate);
	const new_start_date = subtract_period(startDate, load_period);
	return nameOfCurrentPeriod(period, new_start_date, t);
}

/*
 * Shows loading, nothing to see or nothing
 * 
 * @param {number} loadedStatus - status of loading
 * @param {dictionary} data - loaded data
 * @return {component} - info, whether its loading or emty 
 */
export function LoadingDataInfo({ loadedStatus, data, withoutLoading = false, specialCase = false }) {
	const { t } = useTranslation();

	const noData = data.length === 0;
	const loading = loadedStatus === 1;
	const loaded_success = loadedStatus === 2;
	if (specialCase && loading) {
		return <div className='text-center'><Loading /></div>;
	} else if (noData && loading) {
		return withoutLoading ? "" : <div className='text-center'><Loading /></div>;
	} else if (noData && loaded_success) {
		return <p className='text-center'>{t('nothing_to_display')}.</p>;
	} else {
		return "";
	}
}

/**
 * Returns name and year of the current period to load
 * 
 * @param {string} period - timespan week, month, quarter, year
 * @param {string} startDate - ISO date of the current period
 * @param {function} tt - translate function
 * @return {string} - human label of the current period to load
 */
export function nameOfCurrentPeriod(period, startDate, tt) {
	const t = tt || ((s) => s);

	const myStartDate = new Date(startDate);
	const thisYear = myStartDate.getFullYear();
	const month = myStartDate.getMonth();
	//console.log(quarters[3][1]);
	//console.log("le mesic: " +  month);
	switch (period) {
		case "1y":
			return (<>{t('year')}&nbsp;{thisYear}</>);
		case "1m":
			return (<>{t(mesice[month][1])}&nbsp;{thisYear}</>);
		case "1q":
			return (<>{t(quarters[month])}&nbsp;{thisYear}</>);
		case "1w":
			const endOfWeek = myStartDate.setDate(myStartDate.getDate() + 7);
			return (<>{t('week')}&nbsp;{date_formatCZ(startDate)}&nbsp;{date_formatCZ(endOfWeek)}</>);
		default:
			return t('unknown');
	}
}

/*
 * Returns items filtered/items ang button for clearing filters
 * 
 * @param {array} filtered_data - entries at the page after filtering
 * @param {array} data - full list of entries
 * @param {function} cleanFilters - function for cleaning all filters on the page
 * @param {string} itemsName - name of filtered entries, default "záznamů"
 * @return {React.JSX.Element} - span with label and button
 */
export function ItemsAndFiltered({ filtered_data, data, cleanFilters, itemsName = "records" }) {
	return <ItemsAndFilteredPaged totalCount={data.length} filteredCount={filtered_data.length} cleanFilters={cleanFilters} itemsName={itemsName} />;
}

export function ItemsAndFilteredPaged({totalCount, filteredCount, cleanFilters, itemsName = "records" }) {
	const { t } = useTranslation();

	return (
		totalCount !== filteredCount ?
			<span className='text-muted'>{t('filtered')} {filteredCount}/{totalCount} {t(itemsName)}
				<Button onClick={cleanFilters} title={t('clear_filters')} className='ms-2' size="sm" variant='light'><RiFilterOffLine /></Button></span>
			:
			<span className='text-muted'>{totalCount} {t(itemsName)}</span>
	);
}
