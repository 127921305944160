/**
 * App component handiling loggin.
 * @module App
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */

import {
  keepPreviousData,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppComponent } from "./appcomponent";
import { useMsal } from "@azure/msal-react";
import { UserLoginProvider } from "./UserLoginProvider";


const queryClient = new QueryClient();
queryClient.setDefaultOptions({ queries: { retry: false, placeholderData: keepPreviousData, refetchOnWindowFocus: false } });

function App() {

  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const [logged, setLogged] = useState(false);
  const [username, setUsername] = useState("");
  const [userinfo, setUserinfo] = useState({});
  const [justLoggedOut, setJustLoggedOut] = useState(false);
  const [logerr, setLogerr] = useState(false);
  const [logerrMessage, setLogerrMessage] = useState("");
  const [title, setTitle] = useState("HIS");
  const [pingLang, setPingLang] = useState("cs"); //default cs, if ping lang is null

  function handleLogin(username, userinfo) {
    setLogged(true);
    setUsername(username);
    setUserinfo(userinfo);
  }

  function handleLogout() {
    setLogged(false);
    setUsername("");
    setUserinfo({});
    setJustLoggedOut(true);
  }

  function handleLogerr(err, msg = t("login_error")) {
    setLogerr(err);
    handleLogout();
    setLogerrMessage(msg);
  }

  useEffect(() => {
    function changeLanguage(val) {
      i18n.changeLanguage(val);
    }

    changeLanguage(userinfo && userinfo.language ? userinfo.language : pingLang); //language is taken from userlogin or from ping, if userlogin is missing
  }, [userinfo, i18n, pingLang]); //is this legit?

  const { instance: msalInstance } = useMsal();

  const userlogin = {
    username: username,
    logged: logged,
    handleLogin: handleLogin,
    handleLogout: handleLogout,
    userinfo: userinfo,
    handleLogerr: handleLogerr,
    logerr: logerr,
    logmessage: logerrMessage,
    title: title,
    setTitle: setTitle,
    msalInstance,
    justLoggedOut,
  };
  
  const contextUserLogin = {
    userName: username,
    logged: logged,
    userInfo: userinfo,
  }


  return (
    <QueryClientProvider client={queryClient}>
      <UserLoginProvider userLoginData={contextUserLogin}>
        <AppComponent userlogin={userlogin} setPingLang={setPingLang} />
      </UserLoginProvider>
    </QueryClientProvider>
  );
}

export default App;
