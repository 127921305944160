import { authorizedFetch } from "./common";

export function getPurchaseOrders(
  from: string,
  to: string,
  take: number = 10,
  skip: number = 0,
  filter: unknown = {},
  orderBy: string = undefined,
  options?: RequestInit
) {
  return authorizedFetch<Dto.GetPurchaseOrdersItemRangePagedResponse>(
    `/api/orders/purchase/range/${from}/${to}/paged?take=${take}&skip=${skip}&filter=${encodeURIComponent(JSON.stringify(filter))}` +
      (orderBy ? `&orderBy=${orderBy}` : ""),
    { ...options, method: "GET" }
  );
}

export function getSaleOrders(
  from: string,
  to: string,
  take: number = 10,
  skip: number = 0,
  filter: unknown = {},
  options?: RequestInit
) {
  return authorizedFetch<Dto.GetSaleOrdersPagedItemRangePagedResponse>(
    `/api/orders/sale/range/${from}/${to}/paged?take=${take}&skip=${skip}&filter=${encodeURIComponent(JSON.stringify(filter))}`,
    { ...options, method: "GET" }
  );
}
