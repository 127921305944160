/**
 * Check functions for product specifications.
 * @module products/product-checks
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import warehouses_packaging from './warehouses-packaging.json';
import { warehouses_remap } from '../lists/warehouses-defs';
import { valid_packaging_groups } from '../lists/warehouses-defs';
import { check_package_weight_kg_input } from '../lib/generic-checks';
import { defined_pH_classes } from './product-config';

//seen all - OK

/*
Chybové hlášky v tomto souboru, aktualizace 21.1.2022

OBAL
- TWIST: není uvedena specifikace obalu!
- Sklad neeviduje obal!
- Specifikace obalu se liší mezi skladem a HSH!
OBSAH, MJ, PŘEPOČET, VÁHA 1 BALENÍ - KARTA 
- TWIST: není uveden obsah v MJ.
- TWIST: obsah v MJ musí být číslo.
- TWIST: není uvedena měrná jednotka produktu (MJ). 
- TWIST: není uveden přepočet kg/MJ.
- TWIST: přepočet kg/MJ musí být číslo.
- Sklad neeviduje kartu tohoto produktu!
- Chybějící údaje ve skladové kartě produktu na straně skladu!
- Sklad neeviduje obsah v MJ.
- Obsah MJ se liší mezi skladem a HSH!
- Sklad neeviduje měrnou jednotku produktu (MJ).
- Měrná jednotka (MJ) se liší mezi skladem a HSH!
- Sklad neeviduje přepočet kg/MJ.
- Přepočet kg/MJ se liší mezi skladem a HSH!
- Nelze provést kontrolu hmotnosti balení – chybí hodnoty pro výpočet na straně skladu.
- Hmotnost 1 balení se liší mezi skladem a HSH!
ADR PROBLÉMY
- Produkt není ADR, ale má vyplněnou třídu ADR.
- Produkt je ADR a nemá vyplněnou třídu ADR!
- Produkt je ADR a nemá vyplněnou obalovou skupinu!
- Produkt má chybně vyplněnou obalovou skupinu!
- Produkt není ADR, ale má vyplněnou obalovou skupinu.
- Produkt není ADR, ale má vyplněné číslo UN.
- Produkt je ADR a nemá vyplněné číslo UN!
- Produkt je ADR a ve skladu není evidována třída ADR!
- Produkt není ADR, ale ve skladu je evidována třída ADR!
- Produkt je ADR a ve skladu není evidována obalová skupina!
- Produkt není ADR, ale ve skladu je evidována obalová skupina!
- Produkt je ADR a ve skladu není evidováno číslo UN!
- Produkt není ADR, ale ve skladu je evidováno číslo UN!
- Třída se liší mezi skladem a HSH!
- Obalová skupina se liší mezi skladem a HSH!
- Číslo UN se liší mezi skladem a HSH!
JINÉ PROBLÉMY
- HIS: není vyplněna expirace v měsících!
- TWIST: produkt není schválen k nákupu!
- HIS: karta produktu není schválena!
- HIS: chybí nastavení sledování šarží a expirací!
- HIS upozornění: produkt je ADR, kartu lze poslat jen do ADR skladu.
- Položka je duplicitní
PARTNEROVY ADRESY
- TWIST: partner nemá definovanou adresu!" : "TWIST: nebyl nalezen dodavatel produktu!
- TWIST: partner má více základních adres!
- TWIST: partner nemá zadánu základní adresu!
ZÁKLADNÍ OBALY
- TWIST: produkt nemá žádný základní obal.
- TWIST: produkt má více základních obalů.
*/

const excluded_package_groups = ["1", "2", "5.2", "6.2", "7"];

/**
 * Creates unique tag for given id and check. Must be used both by actual checks and their view.
 *
 * @param {string} id - product instance id (item id + hsh/wh, warehouse id...)
 * @param {string} check - check name
 * @return {string} - tag to be used for performCheck and ErrorBadge
 */
export function make_product_check_tag(id, check) {
	return '' + check + '-' + id;
}

/**
 * Verifies whether product container/packaging is properly defined in Twist.
 *
 * @param {function} performCheck - from checker
 * @param {string} id - unique product instance identifier (see make_product_check_tag)
 * @param {num} npj_HmotnostMj - number
 * @param {string} npj_MjHmotnostiMj - unit  
 * @return - nothing
 */
export function check_NO_unit_uniformity(performCheck, id, npj_HmotnostMj, npj_MjHmotnostiMj, soft = false) {
	const danger = soft ? "warning" : "danger";
	performCheck(make_product_check_tag(id, "NO-unit-uniformity"),
		danger,
		function () {
			if (npj_MjHmotnostiMj === null || npj_HmotnostMj === null) {
				return "twist-unit_item_mistake_unify_in_Twist";
			}
			return false;
		});
}


/**
 * Converts container name between warehouse and HSH.
 *
 * @param {number} wid - warehouse id
 * @param {string} container - container name in warehouse
 * @return {string} - container name match in HSH or original container name if no mapping was found
 */
export function convert_warehouse_container(wid, container) {
	const rwids = '00' + wid;
	const rwid = rwids.substring(rwids.length - 2);
	if (warehouses_packaging[rwid]) {
		const warehouse_packaging = warehouses_packaging[rwid];
		if (warehouse_packaging[container]) {
			const wpacksdef = warehouse_packaging[container];
			return '' + container + ' (' + wpacksdef.join(", ") + ')';
		}
	}
	return container;
}

/**
 * Verifies whether product container/packaging is properly defined in Twist.
 *
 * @param {function} performCheck - from checker
 * @param {string} id - unique product instance identifier (see make_product_check_tag)
 * @param {string} k_HSHObal5 - container specification from Twist DB
 * @return - nothing
 */
export function check_product_hsh_container(performCheck, id, k_HSHObal5) {
	performCheck(make_product_check_tag(id, "k_HSHObal5"),
		"danger",
		function () {
			if (!product_warehouse_property_defined(k_HSHObal5)) {
				return "twist-package_missing";
			}
			return false;
		});
}

/**
 * Checks whether given container/packaging is valid for given warehouse.
 *
 * @param {number} wid - warehouse id
 * @param {string} warehouse_pack - warehouse container/packaging specification
 * @param {string} hsh_pack - HSH container/packaging specification
 * @return {boolean} - true if it matches
 */
export function check_warehouse_container_name(wid, warehouse_pack, hsh_package) {
	const hsh_pack = hsh_package ? hsh_package : "";
	const rwids = '00' + wid;
	const rwid0 = rwids.substring(rwids.length - 2);
	//const rwid_old = rwid0 == '29' ? 27 : rwid0; //tabulka s mapováním in the future
	//console.log(rwid_old);
	const rwid = warehouses_remap[rwid0];
	//console.log(rwid);
	if (warehouse_pack.trim().toLowerCase() === hsh_pack.trim().toLowerCase()) {
		return true;
	}
	if (warehouses_packaging[rwid]) {
		const warehouse_packaging = warehouses_packaging[rwid];
		if (warehouse_packaging[warehouse_pack]) {
			const wpacksdef = warehouse_packaging[warehouse_pack];
			for (var idx in wpacksdef) {
				const wpack = wpacksdef[idx];
				if (wpack.toLowerCase() === hsh_pack.toLowerCase()) {
					return true;
				}
			}
			return false;
		}
	}
	return warehouse_pack.trim().toLowerCase() === hsh_pack.trim().toLowerCase();
}

/**
 * Verifies whether product container/packaging is the same in Twist and in given warehouse
 *
 * @param {function} performCheck - from checker
 * @param {string} id - unique product instance identifier (see make_product_check_tag)
 * @param {string} hsh_k_HSHObal5 - container specification from Twist DB
 * @param {number} wid - warehouse id
 * @param {string} k_HSHObal5 - container specification from warehouse card export
 * @param {boolean} soft - warning or danger class error
 * @return - nothing
 */
export function check_product_warehouse_container(performCheck, id, hsh_k_HSHObal5, wid, k_HSHObal5, soft = false) {
	const danger = soft ? "warning" : "danger";
	performCheck(make_product_check_tag(id, "k_HSHObal5"),
		danger,
		function () {
			if (!k_HSHObal5) {
				return "no_package_in_wh";
			}
			if (check_warehouse_container_name(wid, k_HSHObal5, hsh_k_HSHObal5)) {
				return false;
			}
			return "package_differs_in_hsh_and_wh";
		});
}


export function is_unit_kg(unit) {
	const isKg = unit ? unit.trim() === 'kg' : false;
	return isKg;
}

export function check_product_hsh_amount(performCheck, id, MnozstviSklMjVObalu, soft = false,) {
	const importance = soft ? "warning" : "danger";
	performCheck(make_product_check_tag(id, "amount"),
		importance,
		function () {
			if (!MnozstviSklMjVObalu) {
				return "twist-missing_content_in_unit"
			}
			if (isNaN(MnozstviSklMjVObalu)) {
				return "twist-content_must_be_number"
			}
			return false;
		});
}

export function check_product_hsh_MJ(performCheck, id, p_KodMjSkl, soft = false,) {
	const importance = soft ? "warning" : "danger";

	performCheck(make_product_check_tag(id, "unit"),
		importance,
		function () {
			if (!p_KodMjSkl) {
				return "twist-missing_product_measure_unit";
			}
			return false;
		});
}

export function check_product_hsh_density(performCheck, id, spj_HmotnostMj, spj_MjHmotnostiMj, soft = false,) {
	const importance = soft ? "warning" : "danger";

	performCheck(make_product_check_tag(id, "twist-unit-kg"),
		importance,
		function () {
			if (!is_unit_kg(spj_MjHmotnostiMj) || !spj_HmotnostMj) {
				return "twist-missing_kg/mj";
			}
			if (isNaN(spj_HmotnostMj)) {
				return "twist-kg/mj_must_be_number";
			}
			return false;
		});
}


export function check_card_in_warehouse(performCheck, id, pcr_repeats) {
	performCheck(make_product_check_tag(id, "missing-card"),
		"danger",
		function () {
			if (pcr_repeats === null) {
				return "no_product_card_in_wh";
			}
			return false;
		});
}

export function check_product_warehouse_values(performCheck, id, pcr_NazevProduktu, pcr_HmotnostMjVKg,
	pcr_MnozstviSklMjVObalu, pcr_KodMjSkl, soft = false) {
	const importance = soft ? "warning" : "danger";

	performCheck(make_product_check_tag(id, "missing-values"),
		importance,
		function () {
			if (!pcr_NazevProduktu || !pcr_HmotnostMjVKg || !pcr_MnozstviSklMjVObalu || !pcr_KodMjSkl) {
				return "missing_records_on_prooduct_card_in_wh";
			}
			return false;
		});
}

/**
 * Checks whether warehouse and HSH has same number for amount in package
 * 
 * @param {function} performCheck - performCheck funtion
 * @param {string} id - id of check
 * @param {*} pcr_MnozstviSklMjVObalu - amount in packaging in storage units for warehouse
 * @param {*} MnozstviSklMjVObalu - amount in packaging in storage units for HSH
 * @param {boolean} soft - whether final result should be danger or warning
 */
export function check_product_wh_amount(performCheck, id, pcr_MnozstviSklMjVObalu, MnozstviSklMjVObalu, soft = false,) {
	const amount_hsh = MnozstviSklMjVObalu ? MnozstviSklMjVObalu : "";
	const importance = soft ? "warning" : "danger";
	performCheck(make_product_check_tag(id, "amount"),
		importance,
		function () {
			if (!pcr_MnozstviSklMjVObalu || isNaN(pcr_MnozstviSklMjVObalu)) {
				return "no_unit_in_wh";
			}
			if ((amount_hsh.toString() !== pcr_MnozstviSklMjVObalu.toString())) {
				return "unit_content_differs_wh_hsh";
			}
			return false;
		});
}

/**
 * Checks whether warehouse and HSH have same unit for product
 * 
 * @param {function} performCheck - performCheck funtion
 * @param {string} id - id of check
 * @param {string} pcr_KodMjSkl  - warehouse unit
 * @param {string} p_KodMjSkl - HSH unit
 * @param {boolean} soft - whether final result should be danger or warning
 */
export function check_product_wh_MJ(performCheck, id, pcr_KodMjSkl, p_KodMjSkl, soft = false,) {
	const importance = soft ? "warning" : "danger";

	performCheck(make_product_check_tag(id, "unit"),
		importance,
		function () {
			if (!pcr_KodMjSkl) {
				return "no_measure_unit_in_wh";
			}
			if ((pcr_KodMjSkl || "").toLowerCase().trim() !== (p_KodMjSkl || "").toLowerCase().trim()) {
				return "measure_unit_differs_wh_hsh";
			}
			return false;
		});
}

/**
 * Checks whether warehouse and HSH have same number as weight ofone storage unit in kg 
 * 
 * @param {function} performCheck - performCheck funtion
 * @param {string} id - id of check
 * @param {number} pcr_HmotnostMjVKg - weight of one storage unit in kg for warehouse
 * @param {number} spj_HmotnostMj - weight of one storage unit in kg for HSH
 * @param {boolean} soft - whether final result should be danger or warning
 */
export function check_product_warehouse_density(performCheck, id, pcr_HmotnostMjVKg, spj_HmotnostMj, soft = false,) {
	const importance = soft ? "warning" : "danger";

	performCheck(make_product_check_tag(id, "warehouse-unit-kg"),
		importance,
		function () {
			if (!pcr_HmotnostMjVKg) {
				return "no_kg/mj_in_wh";
			}
			if (pcr_HmotnostMjVKg !== spj_HmotnostMj) {
				return "kg/mj_differs_hsh_wh";
			}
			return false;
		});
}

/**
 * Checks whether we have right values to count package weight for warehouse
 * 
 * @param {function} performCheck - performCheck funtion
 * @param {string} id - id of check
 * @param {number} pcr_MnozstviSklMjVObalu - amount of storage units in package
 * @param {number} pcr_HmotnostMjVKg - weight of one storage unit in kg
 * @param {boolean} soft - whether final result should be danger or warning
 */
export function check_package_weight_wh_values(performCheck, id, pcr_MnozstviSklMjVObalu, pcr_HmotnostMjVKg, soft = false) {
	const importance = soft ? "warning" : "danger";

	performCheck(make_product_check_tag(id, 'missing-amount-or-density'),
		importance,
		function () {
			const [ok, errorMessage] = check_package_weight_kg_input(pcr_HmotnostMjVKg, "kg", pcr_MnozstviSklMjVObalu); // we dont check unit from warehouse
			if (ok === true) {
				return false;
			} else {
				return errorMessage;
			}
			// sem patří funkce check_package_weight_kg_input - pokud to klapne, tak false, jinak vrátit message z toho const result = [ok, message]
			/*
			if (!pcr_MnozstviSklMjVObalu || !pcr_HmotnostMjVKg) {
				return "Nelze provést kontrolu hmotnosti balení – chybí hodnoty pro výpočet na straně skladu.";
			}
			return false;
			*/
		});
}

/**
 * Compares weight of package from warehouse and from HSH.
 * 
 * @param {function} performCheck - performCheck funtion
 * @param {string} id - id of check
 * @param {any} weight_hsh - weight get from hsh
 * @param {any} weight_wh - weight got from warehouse
 * @param {boolean} soft - whether final result should be danger or warning
 */
export function package_weight_compare(performCheck, id, weight_hsh, weight_wh, soft = false,) {
	//console.log(weight_hsh)	;
	//console.log(weight_wh);
	const importance = soft ? "warning" : "danger";
	//console.log(make_product_check_tag(id, "package-weight-compare"));
	// pokud weight_hsh || weight_wh budou pomlčky, tak vrať, že to nelze spočítat!!
	performCheck(make_product_check_tag(id, "package-weight-compare"),
		importance,
		function () {
			if (weight_hsh === null || weight_wh === null) {
				return "cannot_compare_package_size";
			}
			const fraction_same = weight_hsh.s === weight_wh.s && weight_hsh.n === weight_wh.n && weight_hsh.d === weight_wh.d;
			if (!fraction_same) {
				return "package_weight_differs_wh_hsh";
			}
			return false;
		});
}


/**
 * Returns roman numeral based on internal Twist mapping of packaging group numbers.
 *
 * @param {number} twist_number - 0, 1, 2 - others are invalid!
 * @return {string} - the roman numeral representation
 */

export function romanize_packaging_group(twist_number) {
	const twist_number_int = isNaN(twist_number) ? parseInt(twist_number) : twist_number;
	const romanized = valid_packaging_groups[twist_number_int];
	if (romanized) {
		return romanized;
	} else {
		return "error";
	}
}

/**
 * Checks whether given packaging group is valid
 *
 * @param {number} twist_number - 0, 1, or 2
 * @return {boolean} - true if valid
 */
function is_packaging_group_valid(twist_number) {
	const twist_number_int = isNaN(twist_number) ? parseInt(twist_number) : twist_number;
	return (twist_number_int >= 0) &&
		(twist_number_int <= 2);
}

/**
 * Checks whether the product ADR specification is consistent.
 *
 * @param {function} performCheck - from checker
 * @param {string} id - unique product instance identifier (see make_product_check_tag)
 * @param {boolean} k_ADR - whether this product must have ADR information
 * @param {string} TridaADR - ADR class
 * @param {string} k_ADRObalovaSkupina - packaging group (must be valid)
 * @param {string} k_ADRUNCislo - UN number
 * @return - nothing
 */
export function check_product_hsh_adr(performCheck, id, k_ADR, TridaADR, k_ADRObalovaSkupina, k_ADRUNCislo) {
	performCheck(make_product_check_tag(id, "TridaADR"),
		"warning",
		function () {
			if (!k_ADR && TridaADR) {
				return "product_isnt_ADR_but_has_ARD_class";
			}
			return false;
		});
	performCheck(make_product_check_tag(id, "TridaADR2"),
		"danger",
		function () {
			if (k_ADR) {
				if (!TridaADR) {
					return "ADR_class_is_missing_for_ADR_product";
				}
			}
			return false;
		});
	performCheck(make_product_check_tag(id, "k_ADRObalovaSkupina"),
		"danger",
		function () {
			if (k_ADR) {
				if (excluded_package_groups.filter((cl) => cl === ('' + TridaADR).trim()).length > 0) {
					// Skip k_ADRObalovaSkupina check for specific classes #561
					return false;
				}
				if (k_ADRObalovaSkupina === null) {
					return "package_group_is_missing_for_ARD_product";
				}
				if (!is_packaging_group_valid(k_ADRObalovaSkupina)) {
					return "incorrect_package_group";
				}
			}
			return false;
		});
	performCheck(make_product_check_tag(id, "k_ADRObalovaSkupina2"),
		"warning",
		function () {
			if (!k_ADR && k_ADRObalovaSkupina) {
				return "product_isnt_ARD_but_has_package_group";
			}
			return false;
		});
	performCheck(make_product_check_tag(id, "k_ADRUNCislo"),
		"warning",
		function () {
			if (!k_ADR && k_ADRUNCislo) {
				return "product_isnt_ADR_but_has_UN_number";
			}
			return false;
		});
	performCheck(make_product_check_tag(id, "k_ADRUNCislo2"),
		"danger",
		function () {
			if (!k_ADR && k_ADRObalovaSkupina) {
				return "UN_number_is_missing_for_ADR_product";
			}
			return false;
		});
}

/**
 * Checks whether given property is defined in warehouse product cards report.
 *
 * @param {string} p - property value
 * @return {boolean} - true if it is defined
 */
export function product_warehouse_property_defined(p) {
	if (p == null) {
		return false;
	}
	if (p === '') {
		return false;
	}
	if (p === false) {
		return false;
	}
	return true;
}

/**
 * Verifies coherence between HSH and warehouse product ADR properties.
 *
 * @param {function} performCheck - from checker
 * @param {string} id - unique product instance identifier (see make_product_check_tag)
 * @param {boolean} hsh_k_ADR - whether this product must have ADR information
 * @param {string} hsh_TridaADR - ADR class
 * @param {string} hsh_k_ADRObalovaSkupina - packaging group (must be valid)
 * @param {string} hsh_k_ADRUNCislo - UN number
 * @param {string} TridaADR - ADR class
 * @param {string} k_ADRObalovaSkupina - packaging group (must be valid)
 * @param {string} k_ADRUNCislo - UN number
 * @return - nothing
 */
export function check_product_warehouse_adr(performCheck, id,
	hsh_k_ADR, hsh_TridaADR, hsh_k_ADRObalovaSkupina, hsh_k_ADRUNCislo,
	TridaADR, k_ADRObalovaSkupina, k_ADRUNCislo,
	soft = false) {
	const danger = soft ? "warning" : "danger";

	performCheck(make_product_check_tag(id, "TridaADR"),
		danger,
		function () {
			if (hsh_k_ADR && !TridaADR) {
				return "missing_ADR_class_in_wh";
			}
			return false;
		});
	performCheck(make_product_check_tag(id, "TridaADR2"),
		danger,
		function () {
			if (!hsh_k_ADR && product_warehouse_property_defined(TridaADR)) {
				return "redundant_ARD_class_in_wh";
			}
			return false;
		});
	performCheck(make_product_check_tag(id, "k_ADRObalovaSkupina"),
		danger,
		function () {
			if (excluded_package_groups.filter((cl) => cl === ('' + TridaADR).trim()).length > 0) {
				// Skip k_ADRObalovaSkupina check for specific classes #561
				return false;
			}
			if (hsh_k_ADR && !k_ADRObalovaSkupina) {
				return "missing_package_group_in_wh";
			}
			return false;
		});
	performCheck(make_product_check_tag(id, "k_ADRObalovaSkupina2"),
		danger,
		function () {
			if (!hsh_k_ADR && product_warehouse_property_defined(k_ADRObalovaSkupina)) {
				return "redundant_package_goup_in_wh";
			}
			return false;
		});
	performCheck(make_product_check_tag(id, "k_ADRUNCislo"),
		danger,
		function () {
			if (hsh_k_ADR && !k_ADRUNCislo) {
				return "missing_UN_number_in_wh";
			}
			return false;
		});
	performCheck(make_product_check_tag(id, "k_ADRUNCislo2"),
		danger,
		function () {
			if (!hsh_k_ADR && product_warehouse_property_defined(k_ADRUNCislo)) {
				return "redundant_UN_number_in_wh";
			}
			return false;
		});

	if (hsh_k_ADR && TridaADR && k_ADRObalovaSkupina && k_ADRUNCislo) {
		//console.log(hsh_k_ADR);
		//console.log(k_ADRUNCislo);
		//console.log(k_ADRObalovaSkupina);
		performCheck(make_product_check_tag(id, "wrong-wh-adr"),
			danger,
			function () {
				if (hsh_TridaADR.toString().trim() !== TridaADR.toString().trim()) {
					return "ADR_class_differs_hsh_wh";
				}
				return false;
			});
		performCheck(make_product_check_tag(id, "wrong-wh-package-group"),
			danger,
			function () {
				if (romanize_packaging_group(hsh_k_ADRObalovaSkupina) !== k_ADRObalovaSkupina.trim()) {
					return "package_group_differs_hsh_wh";
				}
				return false;
			});
		performCheck(make_product_check_tag(id, "wrong-wh-unnum"),
			danger,
			function () {
				if (hsh_k_ADRUNCislo.toString().trim() !== k_ADRUNCislo.toString().trim()) {
					return "UN_number_differs_hsh_wh";
				}
				return false;
			});
	}
}

export function check_product_expiration(performCheck, id, pwc_id, pwc_months, soft = false) {
	const importance = soft ? "warning" : "danger";

	performCheck(make_product_check_tag(id, 'monthExpiration'),
		importance,
		function () {
			if ((pwc_id === 2 || pwc_id === 3) && !pwc_months) {
				return "his-expiry_missing";
			}
			return false;
		});
}

export function check_product_twist_approval(performCheck, id, approved_in_twist, soft = false) {
	const importance = soft ? "warning" : "danger";

	performCheck(make_product_check_tag(id, 'twistApproval'),
		importance,
		function () {
			if (!approved_in_twist) {
				return "twist-product_purchase_not_approved";
			}
			return false;
		});
}

export function check_product_his_approval(performCheck, id, needs_approval, approved_in_his, soft = false) {
	const importance = soft ? "warning" : "danger";

	performCheck(make_product_check_tag(id, 'hisApproval'),
		importance,
		function () {
			if (needs_approval) {
				if (approved_in_his) {
					return false;
				}
				return "his-product_card_not_approved";
			}
			return false;
		});
}


export function check_suppliers_addresses(performCheck, id, suppliers, soft = false, PD = false) {
	const importance = soft ? "warning" : "danger";
	performCheck(make_product_check_tag(id, 'addresses'),
		importance,
		function () {
			if (suppliers.length === 0) {
				return PD ? "twist-missing_partner_address" : "twist-missing_supplier_address";
			}
			if (suppliers.length > 1) {
				return "twist-multiple_basic_partner_address";
			}
			return false;
		});
}

// použité jen na partnerovi - ale stejné funkcionality jako na NO, PD, produkt
//tohle by fungovalo i s omezenýma adresama
export function check_address(performCheck, id, addresses, soft = false) {
	const importance = soft ? "warning" : "danger";
	const basic_adrs = addresses ? addresses.filter(sup => sup.TypAdresy === 0) : [];
	//console.log(basic_adrs);
	performCheck(make_product_check_tag(id, 'basic-address'),
		importance,
		function () {
			if (basic_adrs.length === 0) {
				return "twist-missing_partner_basic_address";
			}
			if (basic_adrs.length > 1) {
				return "twist-multiple_basic_partner_address";
			}
			/* nedává smysl, nenulové adresy se ztratily ve filtru
			if (addresses.length === 1 && addresses[0].TypAdresy === null) {
				return "TWIST: partner nemá zadánu základní adresu!";
			}
			*/
			return false;
		});
}


export function check_suppliers_address_type(performCheck, id, suppliers, soft = false) {
	const importance = soft ? "warning" : "danger";
	performCheck(make_product_check_tag(id, 'address'),
		importance,
		function () {
			if (suppliers.length > 0) {
				if (suppliers[0].typadresy === null) {
					return "twist-missing_partner_basic_address";
				}
				if (suppliers[0].NazevAdresy === null) {
					return "twist-partner_missing_adrs_name";
				}
				if (suppliers[0].Adresa === null) {
					return "twist-partner_missing_street";
				}
				if (suppliers[0].Misto === null) {
					return "twist-partner_missing_place";
				}
				if (suppliers[0].PSC === null) {
					return "twist-partner_missing_zip";
				}
				if (suppliers[0].KodStatu === null) {
					return "twist-partner_missing_country";
				}
			}
			return false;
		});
}

export function check_address_name(performCheck, id, addressName, soft = false) {
	const importance = soft ? "warning" : "danger";
	performCheck(make_product_check_tag(id, 'address-name'),
		importance,
		function () {
			if (addressName === null) {
				return "twist-partner_missing_adrs_name";
			}
			return false;
		});
}

export function check_address_street(performCheck, id, street, soft = false) {
	const importance = soft ? "warning" : "danger";
	performCheck(make_product_check_tag(id, 'address-street'),
		importance,
		function () {
			if (street === null) {
				return "twist-partner_missing_street";
			}
			return false;
		});
}

export function check_address_city(performCheck, id, city, soft = false) {
	const importance = soft ? "warning" : "danger";
	performCheck(make_product_check_tag(id, 'address-city'),
		importance,
		function () {
			if (city === null) {
				return "twist-partner_missing_place";
			}
			return false;
		});
}

export function check_address_zip(performCheck, id, zip, soft = false) {
	const importance = soft ? "warning" : "danger";
	performCheck(make_product_check_tag(id, 'address-zip'),
		importance,
		function () {
			if (zip === null) {
				return "twist-partner_missing_zip";
			}
			return false;
		});
}

export function check_address_country_code(performCheck, id, countryCode, soft = false) {
	const importance = soft ? "warning" : "danger";
	performCheck(make_product_check_tag(id, 'address-country-code'),
		importance,
		function () {
			if (countryCode === null) {
				return "twist-partner_missing_country";
			}
			return false;
		});
}




export function check_product_batches_expirations_monitoring(performCheck, id, configured, soft = false) {
	const importance = soft ? "warning" : "danger";
	performCheck(make_product_check_tag(id, 'configured'),
		importance,
		function () {
			if (configured) {
				return false;
			}
			return "his-missing_bbd_batch_monitoring";
		});
}

export function check_product_adr(performCheck, id, adr, soft = false) {
	const importance = soft ? "warning" : "danger";

	performCheck(make_product_check_tag(id, 'isADR'),
		importance,
		function () {
			if (adr) {
				return "his-ADR_product_to_ADR_wh_only";
			}
			return false;
		});
}


//kontrola duplicitních položek na NO
export function check_duplicities(performCheck, id, duplicities, soft = false) {
	const importance = soft ? "warning" : "danger";

	performCheck(make_product_check_tag(id, 'duplicity'),
		importance,
		function () {
			if (duplicities.includes(id)) {
				return "item_is_duplicate";
			}
			return false;
		});
}

export function check_basic_package(performCheck, id, packagings, soft = false) {
	const importance = soft ? "warning" : "danger";
	const basicPackage = packagings.filter(p => p.basic === true);

	performCheck(make_product_check_tag(id, 'packagings'),
		importance,
		function () {
			if (basicPackage.length === 0) {
				return "twist-missing_basic_package";
			}
			if (basicPackage.length > 1) {
				return "twist-multiple_basic_packages";
			}
			return false;
		});
}

export function check_warehouse_existence(performCheck, id, wh_id, soft = false) {
	const importance = soft ? "warning" : "danger";

	performCheck(make_product_check_tag(id, 'wh-existance'),
		importance,
		function () {
			if (!wh_id) {
				return "twist-missing_wh_number_oon_item";
			}
			return false;
		});
}


export function check_item_has_IDProduktu(performCheck, id, IDProduktu, soft = false) {
	const importance = soft ? "warning" : "danger";

	performCheck(make_product_check_tag(id, 'IDProduktu-missing'),
		importance,
		function () {
			if (!IDProduktu) {
				return "twist-missing_product_ID_on_item";
			}
			return false;
		});
}

export function check_product_pH(performCheck, id, product_class, product_pH, soft = false) {
	const importance = soft ? "warning" : "danger";

	performCheck(make_product_check_tag(id, 'pH-missing'),
		importance,
		function () {
			if (!product_pH && defined_pH_classes.includes(product_class)) {
				return "his-missing_pH_on_product";
			}
			return false;
		});
}



